export const companyNames=[
    {
        "id": 1,
        "value": "Advanta"
    },
    {
        "id": 2,
        "value": "Ajeet Seed"
    },
    {
        "id": 3,
        "value": "Bayer"
    },
    {
        "id": 4,
        "value": "Bioseed"
    },
    {
        "id": 5,
        "value": "Corteva"
    },
    {
        "id": 6,
        "value": "CP"
    },
    {
        "id": 7,
        "value": "Dhanaya"
    },
    {
        "id": 8,
        "value": "Kanchan"
    },
    {
        "id": 9,
        "value": "Ganga Kaveri"
    },
    {
        "id": 10,
        "value": "Limagrain"
    },
    {
        "id": 11,
        "value": "NZ"
    },
    {
        "id": 13,
        "value": "Rasi Seeds"
    },
    {
        "id": 14,
        "value": "Syngenta"
    },
    {
        "id": 16,
        "value": "Godavari"
    },
    {
        "id": 17,
        "value": "HITECHVNR"
    },
    {
        "id": 18,
        "value": "Luxmi"
    },
    {
        "id": 19,
        "value": "Mahyco"
    },
    {
        "id": 20,
        "value": "Nuziveedu"
    },
    {
        "id": 21,
        "value": "Rasi"
    },
    {
        "id": 22,
        "value": "Shrikhar"
    },
    {
        "id": 23,
        "value": "Sriram Fertilizer"
    },
    {
        "id": 24,
        "value": "Star Seeds"
    },
    {
        "id": 25,
        "value": "CT Seeds"
    },
    {
        "id": 26,
        "value": "Kamdgiri"
    },
    {
        "id": 27,
        "value": "Kaveri Seeds"
    },
    {
        "id": 28,
        "value": "Harlal Seed"
    },
    {
        "id": 29,
        "value": "Dhanuka Seeds"
    },
    {
        "id": 30,
        "value": "JK Seeds"
    },
    {
        "id": 31,
        "value": "Prabhat Seeds"
    },
    {
        "id": 32,
        "value": "Ajeet"
    },
    {
        "id": 33,
        "value": "Ankur"
    },
    {
        "id": 34,
        "value": "Ganga Kaveri Seeds"
    },
    {
        "id": 35,
        "value": "Nath - Biogene"
    },
    {
        "id": 36,
        "value": "Xylem Seeds"
    },
    {
        "id": 37,
        "value": "Krishidhan"
    },
    {
        "id": 38,
        "value": "Monsanto Seeds"
    },
    {
        "id": 40,
        "value": "Solar"
    },
    {
        "id": 41,
        "value": "Vikram Seeds"
    },
    {
        "id": 42,
        "value": "Green Gold"
    },
    {
        "id": 43,
        "value": "Illegal"
    },
    {
        "id": 44,
        "value": "Tulsi"
    },
    {
        "id": 45,
        "value": "Rallis"
    },
    {
        "id": 46,
        "value": "Seed Works (US Agri)"
    },
    {
        "id": 47,
        "value": "Amar Biotech"
    },
    {
        "id": 48,
        "value": "Arya Hybrid Seeds"
    },
    {
        "id": 49,
        "value": "Navbharat Seeds"
    },
    {
        "id": 50,
        "value": "Pravardhan Seeds"
    },
    {
        "id": 51,
        "value": "Mahabeej (MSSC)"
    },
    {
        "id": 52,
        "value": "Yashoda Seeds"
    },
    {
        "id": 53,
        "value": "JK Agrigenetics"
    },
    {
        "id": 54,
        "value": "Vibha"
    },
    {
        "id": 55,
        "value": "Zuari Seeds"
    },
    {
        "id": 56,
        "value": "Asian"
    },
    {
        "id": 57,
        "value": "Nusan Seeds"
    },
    {
        "id": 58,
        "value": "Veda Seeds"
    },
    {
        "id": 59,
        "value": "Kribhco"
    },
    {
        "id": 60,
        "value": "Fortune Seeds"
    },
    {
        "id": 61,
        "value": "Namdhari Seeds"
    },
    {
        "id": 62,
        "value": "Safal Seeds"
    },
    {
        "id": 63,
        "value": "Yaganti Seeds"
    },
    {
        "id": 64,
        "value": "Aditya Seeds"
    },
    {
        "id": 65,
        "value": "Crystal"
    },
    {
        "id": 66,
        "value": "Rohini Seeds"
    },
    {
        "id": 67,
        "value": "Geo Seeds"
    },
    {
        "id": 68,
        "value": "Satya Agri Tech"
    },
    {
        "id": 69,
        "value": "Nirmal Seeds"
    },
    {
        "id": 70,
        "value": "Manak seeds"
    },
    {
        "id": 71,
        "value": "Meera agro tech"
    },
    {
        "id": 72,
        "value": "New Nandi Seeds"
    },
    {
        "id": 73,
        "value": "New Ronak Seeds"
    },
    {
        "id": 74,
        "value": "Nitya Seeds"
    },
    {
        "id": 75,
        "value": "NSC"
    },
    {
        "id": 76,
        "value": "Pareek seeds"
    },
    {
        "id": 77,
        "value": "Plant- Gene seeds"
    },
    {
        "id": 78,
        "value": "Priya seeds"
    },
    {
        "id": 79,
        "value": "RSSC"
    },
    {
        "id": 80,
        "value": "Shaktiwardhak Seeds"
    },
    {
        "id": 81,
        "value": "Spriha"
    },
    {
        "id": 82,
        "value": "Acsen Hyveg"
    },
    {
        "id": 83,
        "value": "Advanta Seeds"
    },
    {
        "id": 84,
        "value": "Agri Tech"
    },
    {
        "id": 85,
        "value": "Ajit Seeds"
    },
    {
        "id": 86,
        "value": "Aruti Seed"
    },
    {
        "id": 87,
        "value": "Atash"
    },
    {
        "id": 88,
        "value": "Avani Seeds"
    },
    {
        "id": 89,
        "value": "Biostadt"
    },
    {
        "id": 90,
        "value": "Bisco Seed Tech"
    },
    {
        "id": 91,
        "value": "Chambal Fertilizers"
    },
    {
        "id": 92,
        "value": "Dantiwada Seeds"
    },
    {
        "id": 93,
        "value": "Dayal Seeds"
    },
    {
        "id": 94,
        "value": "Delta Agri-genetics"
    },
    {
        "id": 95,
        "value": "Dhaanya Seeds Limited"
    },
    {
        "id": 96,
        "value": "Dhanya"
    },
    {
        "id": 97,
        "value": "Dinkar Seeds Pvt. Ltd.,"
    },
    {
        "id": 98,
        "value": "DSP Seeds"
    },
    {
        "id": 99,
        "value": "Godrej Seeds & agrigenetics"
    },
    {
        "id": 100,
        "value": "Guha Seeds"
    },
    {
        "id": 101,
        "value": "Hi-Gene Seeds"
    },
    {
        "id": 102,
        "value": "Hytech Seeds"
    },
    {
        "id": 103,
        "value": "Indo Agri"
    },
    {
        "id": 104,
        "value": "Kamadgiri Seeds"
    },
    {
        "id": 105,
        "value": "Kanaha Seeds"
    },
    {
        "id": 106,
        "value": "Kanchan Seeds"
    },
    {
        "id": 107,
        "value": "Kaveri Seed"
    },
    {
        "id": 108,
        "value": "Kaveri_Kirthimaan Agrigenetic"
    },
    {
        "id": 109,
        "value": "Kohinoor"
    },
    {
        "id": 110,
        "value": "Krishi dhan"
    },
    {
        "id": 111,
        "value": "Krishna Agra_Krishna Seeds Agra"
    },
    {
        "id": 112,
        "value": "Limagrain Seeds"
    },
    {
        "id": 113,
        "value": "Mahindra Seeds"
    },
    {
        "id": 114,
        "value": "Mahodaya Seeds"
    },
    {
        "id": 115,
        "value": "Mangalam Seeds"
    },
    {
        "id": 116,
        "value": "Marvel Seeds"
    },
    {
        "id": 117,
        "value": "New Gene"
    },
    {
        "id": 118,
        "value": "NRL"
    },
    {
        "id": 119,
        "value": "Ompa Seed"
    },
    {
        "id": 120,
        "value": "Panchaganga Seeds"
    },
    {
        "id": 121,
        "value": "Panchganga Seed"
    },
    {
        "id": 122,
        "value": "Pioneer"
    },
    {
        "id": 123,
        "value": "Prithvi Hybrid Seeds"
    },
    {
        "id": 124,
        "value": "Pure Gene Agritech"
    },
    {
        "id": 125,
        "value": "Sagar Seeds"
    },
    {
        "id": 126,
        "value": "Sandeep Seed"
    },
    {
        "id": 127,
        "value": "Seed Works"
    },
    {
        "id": 128,
        "value": "Seedtech India"
    },
    {
        "id": 129,
        "value": "Shakthi Seeds"
    },
    {
        "id": 130,
        "value": "Shaktivardhak Seeds"
    },
    {
        "id": 131,
        "value": "Shree Ram Seeds"
    },
    {
        "id": 132,
        "value": "Sona Bio Seeds"
    },
    {
        "id": 133,
        "value": "Sona genetics"
    },
    {
        "id": 134,
        "value": "Sriram"
    },
    {
        "id": 135,
        "value": "Supper Seeds"
    },
    {
        "id": 136,
        "value": "Tata"
    },
    {
        "id": 137,
        "value": "Urmi"
    },
    {
        "id": 138,
        "value": "VNR Seeds"
    },
    {
        "id": 139,
        "value": "Western AgriApex"
    },
    {
        "id": 140,
        "value": "Avirat Agri Seeds Private Limited"
    },
    {
        "id": 141,
        "value": "Bio Seed"
    },
    {
        "id": 142,
        "value": "BioStar"
    },
    {
        "id": 143,
        "value": "Crystal Seeds"
    },
    {
        "id": 144,
        "value": "Ganga Kaveri Seed"
    },
    {
        "id": 145,
        "value": "Mahyco seeds"
    },
    {
        "id": 146,
        "value": "Prabhat Seed"
    },
    {
        "id": 147,
        "value": "DVS"
    },
    {
        "id": 148,
        "value": "Gujrat Hybrid Seeds"
    },
    {
        "id": 149,
        "value": "Jagran Seeds"
    },
    {
        "id": 150,
        "value": "Krishna_Swati Seeds Pvt. Ltd.,"
    },
    {
        "id": 151,
        "value": "Rasi Seeds Pvt.Ltd"
    },
    {
        "id": 152,
        "value": "Seedworks International Pvt Ltd"
    },
    {
        "id": 153,
        "value": "Dcm Sri Ram Ltd"
    },
    {
        "id": 154,
        "value": "HyVeg"
    },
    {
        "id": 155,
        "value": "JK Seeds_Kamadgiri"
    },
    {
        "id": 156,
        "value": "Manomay Biosseeds Pvt Ltd"
    },
    {
        "id": 157,
        "value": "Savannah"
    },
    {
        "id": 158,
        "value": "Shreeram Fertilizers_UPL Limited"
    },
    {
        "id": 159,
        "value": "Awasthi Seeds"
    },
    {
        "id": 160,
        "value": "Baba Seed"
    },
    {
        "id": 161,
        "value": "Gujarat state seed corp."
    },
    {
        "id": 162,
        "value": "Krishna Seeds Kherli"
    },
    {
        "id": 163,
        "value": "Kuber seed"
    },
    {
        "id": 164,
        "value": "Super Seeds Hissar"
    },
    {
        "id": 165,
        "value": "Maruti Seed Kherli"
    },
    {
        "id": 166,
        "value": "Shiv Seeds Bharatpur"
    },
    {
        "id": 167,
        "value": "Bhola Seed"
    },
    {
        "id": 168,
        "value": "Golden Seed"
    },
    {
        "id": 169,
        "value": "RajLaxmi Hybrid Seeds"
    },
    {
        "id": 170,
        "value": "Shankar Seeds"
    },
    {
        "id": 171,
        "value": "Vishwas Seeds"
    },
    {
        "id": 172,
        "value": "Goldking Biogene"
    },
    {
        "id": 173,
        "value": "Mahyco Pvt Ltd"
    },
    {
        "id": 174,
        "value": "Nath Bio-Genes Ltd"
    },
    {
        "id": 175,
        "value": "Nuziveedu Seeds Limited"
    },
    {
        "id": 176,
        "value": "Shakti seeds"
    },
    {
        "id": 177,
        "value": "Srikar"
    },
    {
        "id": 178,
        "value": "Indo American"
    },
    {
        "id": 179,
        "value": "Indo- Gulf"
    },
    {
        "id": 181,
        "value": "GMS -H Seed"
    },
    {
        "id": 182,
        "value": "Long Pink"
    },
    {
        "id": 183,
        "value": "Savannah_Sri Ram Bioseed_Siri Seeds"
    },
    {
        "id": 184,
        "value": "Akash Seeds"
    },
    {
        "id": 185,
        "value": "Ankur seed"
    },
    {
        "id": 186,
        "value": "Lima Grain"
    },
    {
        "id": 187,
        "value": "Manisha Biotech"
    },
    {
        "id": 188,
        "value": "Sonam"
    },
    {
        "id": 189,
        "value": "Nidhi"
    },
    {
        "id": 190,
        "value": "Heera Seeds"
    },
    {
        "id": 191,
        "value": "Victory Seed"
    },
    {
        "id": 192,
        "value": "PANRJ Biotech"
    },
    {
        "id": 193,
        "value": "Bajaj Agri Seeds"
    },
    {
        "id": 194,
        "value": "Delta king Seed"
    },
    {
        "id": 195,
        "value": "DELTA SUDHA Seed"
    },
    {
        "id": 196,
        "value": "Dhandev Seed"
    },
    {
        "id": 197,
        "value": "Mahi Seeds"
    },
    {
        "id": 198,
        "value": "Nodai"
    },
    {
        "id": 199,
        "value": "Adama"
    },
    {
        "id": 200,
        "value": "Atul"
    },
    {
        "id": 201,
        "value": "BASF"
    },
    {
        "id": 203,
        "value": "Bharat Insecticides"
    },
    {
        "id": 206,
        "value": "Coromondel Agri Co. & Fertilizers Ltd."
    },
    {
        "id": 208,
        "value": "Crystal Phosphates"
    },
    {
        "id": 209,
        "value": "Dhanuka"
    },
    {
        "id": 210,
        "value": "Dharmaj Crop Guard Ltd."
    },
    {
        "id": 211,
        "value": "FMC"
    },
    {
        "id": 212,
        "value": "Fungicides India Ltd."
    },
    {
        "id": 213,
        "value": "Gharda"
    },
    {
        "id": 214,
        "value": "Godrej"
    },
    {
        "id": 215,
        "value": "Gujarat Super Phosphate"
    },
    {
        "id": 216,
        "value": "H.P.M."
    },
    {
        "id": 217,
        "value": "IFFCO-MC Crop Science"
    },
    {
        "id": 218,
        "value": "Indofil"
    },
    {
        "id": 219,
        "value": "Insecticides India Ltd"
    },
    {
        "id": 220,
        "value": "JU Agrisciences"
    },
    {
        "id": 221,
        "value": "Krishi Rasayan"
    },
    {
        "id": 222,
        "value": "Nagarjuna"
    },
    {
        "id": 223,
        "value": "Nichino india Pvt ltd"
    },
    {
        "id": 225,
        "value": "PI Industries"
    },
    {
        "id": 227,
        "value": "Shreeram Fertilizers"
    },
    {
        "id": 228,
        "value": "Sulphur Mills"
    },
    {
        "id": 229,
        "value": "Sumitomo"
    },
    {
        "id": 230,
        "value": "Swal Corp (part of UPL)"
    },
    {
        "id": 232,
        "value": "Tropical Agro"
    },
    {
        "id": 233,
        "value": "UPL"
    },
    {
        "id": 234,
        "value": "Zuari"
    },
    {
        "id": 235,
        "value": "Other"
    }
]


export const StatesAndDistricts = {
  "Andhra Pradesh": [
    "Anantapur",
    "Chittoor",
    "East Godavari",
    "Guntur",
    "Krishna",
    "Kurnool",
    "Nellore",
    "Prakasam",
    "Srikakulam",
    "Visakhapatnam",
    "Vizianagaram",
    "West Godavari",
    "YSR Kadapa",
  ],
  "Arunachal Pradesh": [
    "Tawang",
    "West Kameng",
    "East Kameng",
    "Papum Pare",
    "Kurung Kumey",
    "Kra Daadi",
    "Lower Subansiri",
    "Upper Subansiri",
    "West Siang",
    "East Siang",
    "Siang",
    "Upper Siang",
    "Lower Siang",
    "Lower Dibang Valley",
    "Dibang Valley",
    "Anjaw",
    "Lohit",
    "Namsai",
    "Changlang",
    "Tirap",
    "Longding",
  ],
  Assam: [
    "Baksa",
    "Barpeta",
    "Biswanath",
    "Bongaigaon",
    "Cachar",
    "Charaideo",
    "Chirang",
    "Darrang",
    "Dhemaji",
    "Dhubri",
    "Dibrugarh",
    "Goalpara",
    "Golaghat",
    "Hailakandi",
    "Hojai",
    "Jorhat",
    "Kamrup Metropolitan",
    "Kamrup",
    "Karbi Anglong",
    "Karimganj",
    "Kokrajhar",
    "Lakhimpur",
    "Majuli",
    "Morigaon",
    "Nagaon",
    "Nalbari",
    "Dima Hasao",
    "Sivasagar",
    "Sonitpur",
    "South Salmara-Mankachar",
    "Tinsukia",
    "Udalguri",
    "West Karbi Anglong",
  ],
  Bihar: [
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran (Motihari)",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur (Bhabua)",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger (Monghyr)",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia (Purnea)",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran",
  ],
  "Chandigarh (UT)": ["Chandigarh"],
  Chhattisgarh: [
    "Balod",
    "Baloda Bazar",
    "Balrampur",
    "Bastar",
    "Bemetara",
    "Bijapur",
    "Bilaspur",
    "Dantewada (South Bastar)",
    "Dhamtari",
    "Durg",
    "Gariyaband",
    "Janjgir-Champa",
    "Jashpur",
    "Kabirdham (Kawardha)",
    "Kanker (North Bastar)",
    "Kondagaon",
    "Korba",
    "Korea (Koriya)",
    "Mahasamund",
    "Mungeli",
    "Narayanpur",
    "Raigarh",
    "Raipur",
    "Rajnandgaon",
    "Sukma",
    "Surajpur ",
    "Surguja",
  ],
  "Dadra and Nagar Haveli (UT)": ["Dadra & Nagar Haveli"],
  "Daman and Diu (UT)": ["Daman", "Diu"],
  "Delhi (NCT)": [
    "Central Delhi",
    "East Delhi",
    "New Delhi",
    "North Delhi",
    "North East Delhi",
    "North West Delhi",
    "Shahdara",
    "South Delhi",
    "South East Delhi",
    "South West  Delhi",
    "West Delhi",
  ],
  Goa: ["North Goa", "South Goa"],
  Gujarat: [
    "Ahmedabad",
    "Amreli",
    "Anand",
    "Aravalli",
    "Banaskantha (Palanpur)",
    "Bharuch",
    "Bhavnagar",
    "Botad",
    "Chhota Udepur",
    "Dahod",
    "Dangs (Ahwa)",
    "Devbhoomi Dwarka",
    "Gandhinagar",
    "Gir Somnath",
    "Jamnagar",
    "Junagadh",
    "Kachchh",
    "Kheda (Nadiad)",
    "Mahisagar",
    "Mehsana",
    "Morbi",
    "Narmada (Rajpipla)",
    "Navsari",
    "Panchmahal (Godhra)",
    "Patan",
    "Porbandar",
    "Rajkot",
    "Sabarkantha (Himmatnagar)",
    "Surat",
    "Surendranagar",
    "Tapi (Vyara)",
    "Vadodara",
    "Valsad",
  ],
  Haryana: [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Fatehabad",
    "Gurgaon",
    "Hisar",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Mahendragarh",
    "Mewat",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamunanagar",
  ],
  "Himachal Pradesh": [
    "Bilaspur",
    "Chamba",
    "Hamirpur",
    "Kangra",
    "Kinnaur",
    "Kullu",
    "Lahaul &amp; Spiti",
    "Mandi",
    "Shimla",
    "Sirmaur (Sirmour)",
    "Solan",
    "Una",
  ],
  "Jammu and Kashmir": [
    "Anantnag",
    "Bandipore",
    "Baramulla",
    "Budgam",
    "Doda",
    "Ganderbal",
    "Jammu",
    "Kargil",
    "Kathua",
    "Kishtwar",
    "Kulgam",
    "Kupwara",
    "Leh",
    "Poonch",
    "Pulwama",
    "Rajouri",
    "Ramban",
    "Reasi",
    "Samba",
    "Shopian",
    "Srinagar",
    "Udhampur",
  ],
  Jharkhand: [
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribag",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahibganj",
    "Seraikela-Kharsawan",
    "Simdega",
    "West Singhbhum",
  ],
  Karnataka: [
    "Bagalkot",
    "Ballari (Bellary)",
    "Belagavi (Belgaum)",
    "Bengaluru (Bangalore) Rural",
    "Bengaluru (Bangalore) Urban",
    "Bidar",
    "Chamarajanagar",
    "Chikballapur",
    "Chikkamagaluru (Chikmagalur)",
    "Chitradurga",
    "Dakshina Kannada",
    "Davangere",
    "Dharwad",
    "Gadag",
    "Hassan",
    "Haveri",
    "Kalaburagi (Gulbarga)",
    "Kodagu",
    "Kolar",
    "Koppal",
    "Mandya",
    "Mysuru (Mysore)",
    "Raichur",
    "Ramanagara",
    "Shivamogga (Shimoga)",
    "Tumakuru (Tumkur)",
    "Udupi",
    "Uttara Kannada (Karwar)",
    "Vijayapura (Bijapur)",
    "Yadgir",
  ],
  Kerala: [
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Kannur",
    "Kasaragod",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Malappuram",
    "Palakkad",
    "Pathanamthitta",
    "Thiruvananthapuram",
    "Thrissur",
    "Wayanad",
  ],
  "Lakshadweep (UT)": [
    "Agatti",
    "Amini",
    "Androth",
    "Bithra",
    "Chethlath",
    "Kavaratti",
    "Kadmath",
    "Kalpeni",
    "Kilthan",
    "Minicoy",
  ],
  "Madhya Pradesh": [
    "Agar Malwa",
    "Alirajpur",
    "Anuppur",
    "Ashoknagar",
    "Balaghat",
    "Barwani",
    "Betul",
    "Bhind",
    "Bhopal",
    "Burhanpur",
    "Chhatarpur",
    "Chhindwara",
    "Damoh",
    "Datia",
    "Dewas",
    "Dhar",
    "Dindori",
    "Guna",
    "Gwalior",
    "Harda",
    "Hoshangabad",
    "Indore",
    "Jabalpur",
    "Jhabua",
    "Katni",
    "Khandwa",
    "Khargone",
    "Mandla",
    "Mandsaur",
    "Morena",
    "Narsinghpur",
    "Neemuch",
    "Panna",
    "Raisen",
    "Rajgarh",
    "Ratlam",
    "Rewa",
    "Sagar",
    "Satna",
    "Sehore",
    "Seoni",
    "Shahdol",
    "Shajapur",
    "Sheopur",
    "Shivpuri",
    "Sidhi",
    "Singrauli",
    "Tikamgarh",
    "Ujjain",
    "Umaria",
    "Vidisha",
  ],
  Maharashtra: [
    "Ahmednagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Beed",
    "Bhandara",
    "Buldhana",
    "Chandrapur",
    "Dhule",
    "Gadchiroli",
    "Gondia",
    "Hingoli",
    "Jalgaon",
    "Jalna",
    "Kolhapur",
    "Latur",
    "Mumbai City",
    "Mumbai Suburban",
    "Nagpur",
    "Nanded",
    "Nandurbar",
    "Nashik",
    "Osmanabad",
    "Palghar",
    "Parbhani",
    "Pune",
    "Raigad",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sindhudurg",
    "Solapur",
    "Thane",
    "Wardha",
    "Washim",
    "Yavatmal",
  ],

  Manipur: [
    "Bishnupur",
    "Chandel",
    "Churachandpur",
    "Imphal East",
    "Imphal West",
    "Jiribam",
    "Kakching",
    "Kamjong",
    "Kangpokpi",
    "Noney",
    "Pherzawl",
    "Senapati",
    "Tamenglong",
    "Tengnoupal",
    "Thoubal",
    "Ukhrul",
  ],
  Meghalaya: [
    "East Garo Hills",
    "East Jaintia Hills",
    "East Khasi Hills",
    "North Garo Hills",
    "Ri Bhoi",
    "South Garo Hills",
    "South West Garo Hills",
    "South West Khasi Hills",
    "West Garo Hills",
    "West Jaintia Hills",
    "West Khasi Hills",
  ],

  Mizoram: [
    "Aizawl",
    "Champhai",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
  ],
  Nagaland: [
    "Dimapur",
    "Kiphire",
    "Kohima",
    "Longleng",
    "Mokokchung",
    "Mon",
    "Peren",
    "Phek",
    "Tuensang",
    "Wokha",
    "Zunheboto",
  ],

  Odisha: [
    "Angul",
    "Balangir",
    "Balasore",
    "Bargarh",
    "Bhadrak",
    "Boudh",
    "Cuttack",
    "Deogarh",
    "Dhenkanal",
    "Gajapati",
    "Ganjam",
    "Jagatsinghapur",
    "Jajpur",
    "Jharsuguda",
    "Kalahandi",
    "Kandhamal",
    "Kendrapara",
    "Kendujhar  (Keonjhar)",
    "Khordha",
    "Koraput",
    "Malkangiri",
    "Mayurbhanj",
    "Nabarangpur",
    "Nayagarh",
    "Nuapada",
    "Puri",
    "Rayagada",
    "Sambalpur",
    "Sonepur",
    "Sundargarh",
  ],
  "Puducherry (UT)": ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
  Punjab: [
    "Amritsar",
    "Barnala",
    "Bathinda",
    "Faridkot",
    "Fatehgarh Sahib",
    "Fazilka",
    "Ferozepur",
    "Gurdaspur",
    "Hoshiarpur",
    "Jalandhar",
    "Kapurthala",
    "Ludhiana",
    "Mansa",
    "Moga",
    "Muktsar",
    "Nawanshahr (Shahid Bhagat Singh Nagar)",
    "Pathankot",
    "Patiala",
    "Rupnagar",
    "Sahibzada Ajit Singh Nagar (Mohali)",
    "Sangrur",
    "Tarn Taran",
  ],
  Rajasthan: [
    "Ajmer",
    "Alwar",
    "Banswara",
    "Baran",
    "Barmer",
    "Bharatpur",
    "Bhilwara",
    "Bikaner",
    "Bundi",
    "Chittorgarh",
    "Churu",
    "Dausa",
    "Dholpur",
    "Dungarpur",
    "Hanumangarh",
    "Jaipur",
    "Jaisalmer",
    "Jalore",
    "Jhalawar",
    "Jhunjhunu",
    "Jodhpur",
    "Karauli",
    "Kota",
    "Nagaur",
    "Pali",
    "Pratapgarh",
    "Rajsamand",
    "Sawai Madhopur",
    "Sikar",
    "Sirohi",
    "Sri Ganganagar",
    "Tonk",
    "Udaipur",
  ],
  Sikkim: ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
  "Tamil Nadu": [
    "Ariyalur",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Salem",
    "Sivaganga",
    "Thanjavur",
    "Theni",
    "Thoothukudi  (Tuticorin)",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ],

  Telangana: [
    "Adilabad",
    "Bhadradri Kothagudem",
    "Hyderabad",
    "Jagtial",
    "Jangaon",
    "Jayashankar Bhoopalpally",
    "Jogulamba Gadwal",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Komaram Bheem Asifabad",
    "Mahabubabad",
    "Mahabubnagar",
    "Mancherial",
    "Medak",
    "Medchal",
    "Nagarkurnool",
    "Nalgonda",
    "Nirmal",
    "Nizamabad",
    "Peddapalli",
    "Rajanna Sircilla",
    "Rangareddy",
    "Sangareddy",
    "Siddipet",
    "Suryapet",
    "Vikarabad",
    "Wanaparthy",
    "Warangal (Rural)",
    "Warangal (Urban)",
    "Yadadri Bhuvanagiri",
  ],

  Tripura: [
    "Dhalai",
    "Gomati",
    "Khowai",
    "North Tripura",
    "Sepahijala",
    "South Tripura",
    "Unakoti",
    "West Tripura",
  ],
  Uttarakhand: [
    "Almora",
    "Bageshwar",
    "Chamoli",
    "Champawat",
    "Dehradun",
    "Haridwar",
    "Nainital",
    "Pauri Garhwal",
    "Pithoragarh",
    "Rudraprayag",
    "Tehri Garhwal",
    "Udham Singh Nagar",
    "Uttarkashi",
  ],

  "Uttar Pradesh": [
    "Agra",
    "Aligarh",
    "Allahabad",
    "Ambedkar Nagar",
    "Amethi (Chatrapati Sahuji Mahraj Nagar)",
    "Amroha (J.P. Nagar)",
    "Auraiya",
    "Azamgarh",
    "Baghpat",
    "Bahraich",
    "Ballia",
    "Balrampur",
    "Banda",
    "Barabanki",
    "Bareilly",
    "Basti",
    "Bhadohi",
    "Bijnor",
    "Budaun",
    "Bulandshahr",
    "Chandauli",
    "Chitrakoot",
    "Deoria",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farrukhabad",
    "Fatehpur",
    "Firozabad",
    "Gautam Buddha Nagar",
    "Ghaziabad",
    "Ghazipur",
    "Gonda",
    "Gorakhpur",
    "Hamirpur",
    "Hapur (Panchsheel Nagar)",
    "Hardoi",
    "Hathras",
    "Jalaun",
    "Jaunpur",
    "Jhansi",
    "Kannauj",
    "Kanpur Dehat",
    "Kanpur Nagar",
    "Kanshiram Nagar (Kasganj)",
    "Kaushambi",
    "Kushinagar (Padrauna)",
    "Lakhimpur - Kheri",
    "Lalitpur",
    "Lucknow",
    "Maharajganj",
    "Mahoba",
    "Mainpuri",
    "Mathura",
    "Mau",
    "Meerut",
    "Mirzapur",
    "Moradabad",
    "Muzaffarnagar",
    "Pilibhit",
    "Pratapgarh",
    "RaeBareli",
    "Rampur",
    "Saharanpur",
    "Sambhal (Bhim Nagar)",
    "Sant Kabir Nagar",
    "Shahjahanpur",
    "Shamali (Prabuddh Nagar)",
    "Shravasti",
    "Siddharth Nagar",
    "Sitapur",
    "Sonbhadra",
    "Sultanpur",
    "Unnao",
    "Varanasi",
  ],

  "West Bengal": [
    "Alipurduar",
    "Bankura",
    "Birbhum",
    "Burdwan (Bardhaman)",
    "Cooch Behar",
    "Dakshin Dinajpur (South Dinajpur)",
    "Darjeeling",
    "Hooghly",
    "Howrah",
    "Jalpaiguri",
    "Kalimpong",
    "Kolkata",
    "Malda",
    "Murshidabad",
    "Nadia",
    "North 24 Parganas",
    "Paschim Medinipur (West Medinipur)",
    "Purba Medinipur (East Medinipur)",
    "Purulia",
    "South 24 Parganas",
    "Uttar Dinajpur (North  Dinajpur)",
  ],
};



export const states = {
    "data": [
        {
            "state_code": "33",
            "state_name": "Tamil Nadu"
        },
        {
            "state_code": "22",
            "state_name": "Chhattisgarh"
        },
        {
            "state_code": "18",
            "state_name": "Assam"
        },
        {
            "state_code": "16",
            "state_name": "Tripura"
        },
        {
            "state_code": "90",
            "state_name": "Telangana"
        },
        {
            "state_code": "2",
            "state_name": "Himachal Pradesh"
        },
        {
            "state_code": "13",
            "state_name": "Nagaland"
        },
        {
            "state_code": "8",
            "state_name": "Rajasthan"
        },
        {
            "state_code": "9",
            "state_name": "Uttar Pradesh"
        },
        {
            "state_code": "6",
            "state_name": "Haryana"
        },
        {
            "state_code": "28",
            "state_name": "Andhra Pradesh"
        },
        {
            "state_code": "30",
            "state_name": "Goa"
        },
        {
            "state_code": "24",
            "state_name": "Gujarat"
        },
        {
            "state_code": "91",
            "state_name": "Jammu And Kashmir"
        },
        {
            "state_code": "27",
            "state_name": "Maharashtra"
        },
        {
            "state_code": "5",
            "state_name": "Uttarakhand"
        },
        {
            "state_code": "4",
            "state_name": "Chandigarh"
        },
        {
            "state_code": "32",
            "state_name": "Kerala"
        },
        {
            "state_code": "34",
            "state_name": "Puducherry"
        },
        {
            "state_code": "23",
            "state_name": "Madhya Pradesh"
        },
        {
            "state_code": "19",
            "state_name": "West Bengal"
        },
        {
            "state_code": "7",
            "state_name": "Delhi"
        },
        {
            "state_code": "11",
            "state_name": "Sikkim"
        },
        {
            "state_code": "3",
            "state_name": "Punjab"
        },
        {
            "state_code": "20",
            "state_name": "Jharkhand"
        },
        {
            "state_code": "29",
            "state_name": "Karnataka"
        },
        {
            "state_code": "21",
            "state_name": "Odisha"
        },
        {
            "state_code": "12",
            "state_name": "Arunachal Pradesh"
        },
        {
            "state_code": "17",
            "state_name": "Meghalaya"
        },
        {
            "state_code": "10",
            "state_name": "Bihar"
        },
        {
            "state_code": "15",
            "state_name": "Mizoram"
        },
        {
            "state_code": "14",
            "state_name": "Manipur"
        }
    ]
}

export const districts = {
    "data": [
        {
            "district_code": "29555",
            "district_name": "Belagavi"
        },
        {
            "district_code": "29556",
            "district_name": "Bagalkote"
        },
        {
            "district_code": "29557",
            "district_name": "Vijayapura"
        },
        {
            "district_code": "29558",
            "district_name": "Bidar"
        },
        {
            "district_code": "29559",
            "district_name": "Raichur"
        },
        {
            "district_code": "29560",
            "district_name": "Koppal"
        },
        {
            "district_code": "29561",
            "district_name": "Gadag"
        },
        {
            "district_code": "29562",
            "district_name": "Dharwad"
        },
        {
            "district_code": "29563",
            "district_name": "Uttara Kannada"
        },
        {
            "district_code": "29564",
            "district_name": "Haveri"
        },
        {
            "district_code": "29565",
            "district_name": "Ballari"
        },
        {
            "district_code": "29566",
            "district_name": "Chitradurga"
        },
        {
            "district_code": "29567",
            "district_name": "Davangere"
        },
        {
            "district_code": "29568",
            "district_name": "Shivamogga"
        },
        {
            "district_code": "29569",
            "district_name": "Udupi"
        },
        {
            "district_code": "29570",
            "district_name": "Chikkamagaluru"
        },
        {
            "district_code": "29571",
            "district_name": "Tumakuru"
        },
        {
            "district_code": "29572",
            "district_name": "Bengaluru Urban"
        },
        {
            "district_code": "29573",
            "district_name": "Mandya"
        },
        {
            "district_code": "29574",
            "district_name": "Hassan"
        },
        {
            "district_code": "29575",
            "district_name": "Dakshina Kannada"
        },
        {
            "district_code": "29576",
            "district_name": "Kodagu"
        },
        {
            "district_code": "29577",
            "district_name": "Mysuru"
        },
        {
            "district_code": "29578",
            "district_name": "Chamarajanagara"
        },
        {
            "district_code": "29579",
            "district_name": "Kalaburagi"
        },
        {
            "district_code": "29580",
            "district_name": "Yadgir"
        },
        {
            "district_code": "29581",
            "district_name": "Kolar"
        },
        {
            "district_code": "29582",
            "district_name": "Chikkaballapura"
        },
        {
            "district_code": "29583",
            "district_name": "Bengaluru Rural"
        },
        {
            "district_code": "29584",
            "district_name": "Ramanagara"
        },
        {
            "district_code": "29900",
            "district_name": "Vijayanagar"
        }
    ]
}

export const subDistricts = {
    data:[
        {
            "sub_district_code": "2958405605",
            "sub_district_name": "Magadi"
        },
        {
            "sub_district_code": "2958405606",
            "sub_district_name": "Ramanagara"
        },
        {
            "sub_district_code": "2958405607",
            "sub_district_name": "Channapatna"
        },
        {
            "sub_district_code": "2958405608",
            "sub_district_name": "Kanakapura"
        },
        {
            "sub_district_code": "2958490000",
            "sub_district_name": "Harohalli"
        }
    ]
}



export const urbanBodies = {
    data: [
        {
            "urban_body_code": "277305",
            "urban_body_name": "Bakloh"
        },
        {
            "urban_body_code": "277307",
            "urban_body_name": "Dalhousie"
        },
        {
            "urban_body_code": "248044",
            "urban_body_name": "Dalhousie"
        }
    ]
}

export const villages = {
    "data": [
        {
            "village_code": "2958405605000145",
            "village_name": "Kenkere"
        },
        {
            "village_code": "2958405605000108",
            "village_name": "Hosahalli"
        },
        {
            "village_code": "2958405605000193",
            "village_name": "Naganahalli"
        },
        {
            "village_code": "2958405605000038",
            "village_name": "Bommanahalli"
        },
        {
            "village_code": "2958405605000240",
            "village_name": "Somakkanamutta"
        },
        {
            "village_code": "2958405605000115",
            "village_name": "Iyandahalli"
        },
        {
            "village_code": "2958405605000143",
            "village_name": "Kenchanahalli"
        },
        {
            "village_code": "2958405605000053",
            "village_name": "Chikkamudigere"
        },
        {
            "village_code": "2958405605000003",
            "village_name": "Agalakote"
        },
        {
            "village_code": "2958405605000055",
            "village_name": "Chikkathorepalya"
        },
        {
            "village_code": "2958405605000077",
            "village_name": "Gattipura"
        },
        {
            "village_code": "2958405605000192",
            "village_name": "Nagamangala"
        },
        {
            "village_code": "2958405605000204",
            "village_name": "Ombathanakunte"
        },
        {
            "village_code": "2958405605000001",
            "village_name": "Adakamaranahalli"
        },
        {
            "village_code": "2958405605000037",
            "village_name": "Bittasandra"
        },
        {
            "village_code": "2958405605000032",
            "village_name": "Bettadasipalya"
        },
        {
            "village_code": "2958405605000029",
            "village_name": "Beeravara"
        },
        {
            "village_code": "2958405605000184",
            "village_name": "Melanahalli"
        },
        {
            "village_code": "2958405605000270",
            "village_name": "Uddhandahalli"
        },
        {
            "village_code": "2958405605000264",
            "village_name": "Thubarapalya"
        },
        {
            "village_code": "2958405605000186",
            "village_name": "Motagondanahalli"
        },
        {
            "village_code": "2958405605000178",
            "village_name": "Marisomanahalli"
        },
        {
            "village_code": "2958405605000258",
            "village_name": "Thippasandra"
        },
        {
            "village_code": "2958405605000126",
            "village_name": "Kallur"
        },
        {
            "village_code": "2958405605000202",
            "village_name": "Nerlawadi"
        },
        {
            "village_code": "2958405605000291",
            "village_name": "Yannegere"
        },
        {
            "village_code": "2958405605000120",
            "village_name": "Kagimadu"
        },
        {
            "village_code": "2958405605000005",
            "village_name": "Ajjanahalli"
        },
        {
            "village_code": "2958405605000066",
            "village_name": "Doddamudigere"
        },
        {
            "village_code": "2958405605000121",
            "village_name": "Kalari"
        },
        {
            "village_code": "2958405605000067",
            "village_name": "Doddasomanahalli"
        },
        {
            "village_code": "2958405605000207",
            "village_name": "Panakanakallu"
        },
        {
            "village_code": "2958405605000159",
            "village_name": "Machohalli"
        },
        {
            "village_code": "2958405605000106",
            "village_name": "Honnapura"
        },
        {
            "village_code": "2958405605000250",
            "village_name": "Tavarekere"
        },
        {
            "village_code": "2958405605000117",
            "village_name": "Juttanahalli"
        },
        {
            "village_code": "2958405605000033",
            "village_name": "Bettahalli"
        },
        {
            "village_code": "2958405605000228",
            "village_name": "Shambhudevanahalli"
        },
        {
            "village_code": "2958405605000277",
            "village_name": "Varthenahalli"
        },
        {
            "village_code": "2958405605000256",
            "village_name": "Thavarekere"
        },
        {
            "village_code": "2958405605000187",
            "village_name": "Mummenahalli"
        },
        {
            "village_code": "2958405605000172",
            "village_name": "Manniganahalli"
        },
        {
            "village_code": "2958405605000281",
            "village_name": "Vengalappanahalli"
        },
        {
            "village_code": "2958405605000104",
            "village_name": "Hemapura"
        },
        {
            "village_code": "2958405605000009",
            "village_name": "Alur"
        },
        {
            "village_code": "2958405605000109",
            "village_name": "Hujagal"
        },
        {
            "village_code": "2958405605000016",
            "village_name": "Bachenahatti"
        },
        {
            "village_code": "2958405605000098",
            "village_name": "Hanumapura."
        },
        {
            "village_code": "2958405605000047",
            "village_name": "Channapura"
        },
        {
            "village_code": "2958405605000137",
            "village_name": "Karagadahalli"
        },
        {
            "village_code": "2958405605000206",
            "village_name": "Palyadahalli"
        },
        {
            "village_code": "2958405605000249",
            "village_name": "Talekere"
        },
        {
            "village_code": "2958405605000056",
            "village_name": "Chikkenahalli"
        },
        {
            "village_code": "2958405605000080",
            "village_name": "Gerahalli"
        },
        {
            "village_code": "2958405605000263",
            "village_name": "Thoreramanahalli"
        },
        {
            "village_code": "2958405605000223",
            "village_name": "Sannenahalli"
        },
        {
            "village_code": "2958405605000116",
            "village_name": "Janigere"
        },
        {
            "village_code": "2958405605000043",
            "village_name": "Byrapura"
        },
        {
            "village_code": "2958405605000130",
            "village_name": "Kalyani Kaval"
        },
        {
            "village_code": "2958405605000129",
            "village_name": "Kalyanapura"
        },
        {
            "village_code": "2958405605000148",
            "village_name": "Kondahalli"
        },
        {
            "village_code": "2958405605000253",
            "village_name": "Thammenahalli"
        },
        {
            "village_code": "2958405605000165",
            "village_name": "Mallasandra"
        },
        {
            "village_code": "2958405605000135",
            "village_name": "Kannanur"
        },
        {
            "village_code": "2958405605000070",
            "village_name": "Donakuppe"
        },
        {
            "village_code": "2958405605000048",
            "village_name": "Channohalli"
        },
        {
            "village_code": "2958405605000025",
            "village_name": "Basavanapalya"
        },
        {
            "village_code": "2958405605000208",
            "village_name": "Papasastripalya"
        },
        {
            "village_code": "2958405605000139",
            "village_name": "Karlamangala"
        },
        {
            "village_code": "2958405605000248",
            "village_name": "T.channapura"
        },
        {
            "village_code": "2958405605000170",
            "village_name": "Managallu"
        },
        {
            "village_code": "2958405605000238",
            "village_name": "Singripalya"
        },
        {
            "village_code": "2958405605000063",
            "village_name": "Dandigepura"
        },
        {
            "village_code": "2958405605000051",
            "village_name": "Chikkakalya"
        },
        {
            "village_code": "2958405605000088",
            "village_name": "Gudepalya"
        },
        {
            "village_code": "2958405605000114",
            "village_name": "Hyaganahalli"
        },
        {
            "village_code": "2958405605000074",
            "village_name": "Gangonahalli"
        },
        {
            "village_code": "2958405605000163",
            "village_name": "Mallappanahalli"
        },
        {
            "village_code": "2958405605000278",
            "village_name": "Veeragowdanadoddi"
        },
        {
            "village_code": "2958405605000028",
            "village_name": "Beechanahalli"
        },
        {
            "village_code": "2958405605000261",
            "village_name": "Thore Channenahalli"
        },
        {
            "village_code": "2958405605000036",
            "village_name": "Biskuru"
        },
        {
            "village_code": "2958405605000189",
            "village_name": "Muthagadahalli"
        },
        {
            "village_code": "2958405605000290",
            "village_name": "Yallapura"
        },
        {
            "village_code": "2958405605000272",
            "village_name": "Vaddara Palya"
        },
        {
            "village_code": "2958405605000284",
            "village_name": "Virupapura"
        },
        {
            "village_code": "2958405605000267",
            "village_name": "Udakunte"
        },
        {
            "village_code": "2958405605000146",
            "village_name": "Kodihalli"
        },
        {
            "village_code": "2958405605000022",
            "village_name": "Bandemata"
        },
        {
            "village_code": "2958405605000198",
            "village_name": "Nasarumavinakatte"
        },
        {
            "village_code": "2958405605000200",
            "village_name": "Nelapatna"
        },
        {
            "village_code": "2958405605000079",
            "village_name": "Gejjagaraguppe"
        },
        {
            "village_code": "2958405605000071",
            "village_name": "Dubbagottige"
        },
        {
            "village_code": "2958405605000199",
            "village_name": "Nayakanapalya"
        },
        {
            "village_code": "2958405605000286",
            "village_name": "Vitalapura"
        },
        {
            "village_code": "2958405605000201",
            "village_name": "Neralekere"
        }
    ]
}

export const FieldsWithACtualData = [
    "AllSeed_section1_2023",
    "AllSeed_totalsale_section1_2024",
    "Biologic_section1_2023",
    "Biologic_totalsale_section1_2024",
    "CP_totalsale_section1_2023",
    "CP_totalsale_section1_2024",
    "PSR_totalsale_section1_2023",
    "PSR_totalsale_section1_2024",
    "company_top_nametop1total",
    "company_top_nametop2total",
    "company_top_nametop3total",
    "company_top_nametop4total",
    "company_top_nametop5total",
    "retailsalesrice_RICE1_totalsale",
    "retailsalesrice_RICE2_totalsale",
    "retailsalesrice_RICE3_totalsale",
    "bayerricetotal2024_BayerRICE_totalsale",
    "solubleFertilizer_section1_2023",
    "solubleFertilizer_totalsale_section1_2024",
    "bulkFertilizer_section1_2023",
    "bulkFertilizer_totalsale_section1_2024",
    "calculated_totalsale_2023",
    "calculated_totalsale_2024",
    
]