import { API_URL } from "./sys";

export const FetchRetailers = `${API_URL}/retailers`;
export const SendOTP = `${API_URL}/otp/send`;
export const VerifyOTPs = `${API_URL}/otp/verify`;

export const get = async (endpoint, params) => {
  const url = new URL(`${API_URL}${endpoint}`);
  if (params) {
    Object.entries(params).forEach(([key, value]) =>
      url.searchParams.append(key, value)
    );
  }

  const response = await fetch(url);
  const data = await response.json();
  return data;
};

export const post = async (endpoint, data) => {
  const response = await fetch(`${API_URL}${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const responseData = await response.json();
  return responseData;
};
