import { colors, Font, Gap } from "../styles/standards";

export const Wrapper = ({ children, fieldTag, isDisabled, isHalf }) => {
  return (
    <div
      style={{
        ...style.container,
        pointerEvents: isDisabled ? "none" : "all",
        opacity: isDisabled ? "0.3" : 1,
        width:isHalf?'50%':'100%'
      }}
    >
      <span style={style.fieldname}>{fieldTag}</span>
      {children}
    </div>
  );
};
const style = {
  container: {
    padding: Gap.standardGap,
    paddingBottom: 0,
  },
  fieldname: {
    ...Font.RobotoMedium,
    display: "block",
    color: colors.Black,
    fontSize: 13,
  },
};
