export const StickyActionContainer = ({children}) => {
    return(
        <div style={style.container}>
            {children}
        </div>
    )
}
const style = {
    container: {
        position:'absolute',
        bottom:0,
        left:0, right:0,
        padding:20
    }
}