import { useEffect, useState } from "react";
import { Header } from "../components/headerTypeOne";
import { Constants } from "../constants/textconstants";
import { config } from "../config/step2";
import { Style } from "../styles/step1";
import { SaveAndCont } from "../components/saveandcont";
import { useLocation, useNavigate } from "react-router-dom";
import TextInputWithUnit from "../components/textInputwithUnit";
import { colors, Font } from "../styles/standards";
import { useDispatch } from "react-redux";
import { showToast } from "../store/slices/util";
import { SingleSelect } from "../helper-functions/utils";
import { ProceedNotification } from "../components/proceedNotify";
import { post } from "../config/api";
import { restructureAnswers, structureData } from "../helper-functions";
import { SaveProgress } from "../components/saveProgress";
import { InfoPopUp } from "../components/infoPopuop";

const calculatePercentageReduction = (totalValue, reductionValue) => {
  const reducedValue = totalValue - reductionValue;
  const percentageReduction = (reductionValue / totalValue) * 100;
  const remainingPercentage = 0 + percentageReduction;
  return remainingPercentage;
};

// HighlightBar Component
const HeighlightBar = ({ typeOfsale, value }) => {
  let dataKeys = [];

  if (typeOfsale === "Crop Protection") {
    dataKeys = ["insectiside", "fungiside", "herbiside", "seedtreatment"];
  } else if (typeOfsale === "Rice(Hybrid Seed)") {
    dataKeys = [
      "retailsalesrice_RICE3",
      "retailsalesrice_RICE2",
      "retailsalesrice_RICE1",
    ];
  } else {
    dataKeys = ["SEE_cornhybrid", "SEE_paddyybrid", "SEE_vegetablehybrid", "SEE_otherhybrid"];
  }

  const totalSales = dataKeys.reduce(
    (sum, key) => sum + (value[`${key}_totalsale`] ?? 0),
    0
  );

  let total2024Key = "";
  if (typeOfsale === "Crop Protection") {
    total2024Key = "CP_totalsale_section1_2024";
  } else if (typeOfsale === "Rice(Hybrid Seed)") {
    total2024Key = "SEE_paddyybrid_retail";
  } else {
    total2024Key = "AllSeed_totalsale_section1_2024";
  }

  const total2024 = value[total2024Key] ?? 0;
  
  let percentageofRice = calculatePercentageReduction(total2024, totalSales)
    ? calculatePercentageReduction(total2024, totalSales)
    : 0.0;

  let colorChecker =
    typeOfsale === "Rice(Hybrid Seed)"
      ? percentageofRice > 100
      : 100 - totalSales < 0;

 
  return (
    <div
      style={{
        background: colorChecker ? "#ffa4a4" : "#e1f2e1",
        padding: 20,
        marginBottom: 20,
        position: "sticky",
        top: 0,
        zIndex: 9,
      }}
    >
      <span style={Style.Totalsale}>Total {typeOfsale} sale in 2024</span>
      <span>
        {total2024 / 100000} Lakhs{" "}
        {typeOfsale === "Rice(Hybrid Seed)"
          ? "(" + percentageofRice.toFixed(2) + "%)"
          : "(" + (0 + totalSales).toFixed(2) + "%)"}
      </span>
      {colorChecker && (
        <span style={{ ...Font.RobotoMedium, fontSize: 13, display: "block" }}>
          Total sales % cant be more than 100% of {typeOfsale} sales
        </span>
      )}
    </div>
  );
};

export const Step2 = () => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fieldErrors, setFieldErrors] = useState({});
  const [reviewInfo, setReviewInfo] = useState(false);
  const data = useLocation();
  const [saveProgress, setSaveProgress] = useState(false);
  const [showError, setShowError] = useState(false);

  const calculateWholeSale = (updatedFormData) => {
    let cornHybrid =
      updatedFormData["SEE_cornhybrid_retail"] *
      (updatedFormData["cornhybrid_wholesalepercentage"] / 100);

    let paddyHybrid =
      updatedFormData["SEE_paddyybrid_retail"] *
      (updatedFormData["paddyybrid_wholesalepercentage"] / 100);

    let veggyHybrid =
      updatedFormData["SEE_vegetablehybrid_retail"] *
      (updatedFormData["vegetablehybrid_wholesalepercentage"] / 100);

    let otherHybrid =
      updatedFormData["SEE_otherhybrid_retail"] *
      (updatedFormData["otherhybrid_wholesalepercentage"] / 100);
    console.log(cornHybrid , paddyHybrid , veggyHybrid , otherHybrid , veggyHybrid,'-++----')

    return (
      ((cornHybrid + paddyHybrid + veggyHybrid + otherHybrid ) /
        updatedFormData["AllSeed_totalsale_section1_2024"]) *
      100
    );
  };

  useEffect(() => {
    dispatch(
      showToast({ toast: true, toastmessage: "Progress saved successfully!" })
    );
    setTimeout(() => {
      dispatch(showToast({ toast: false, toastmessage: "" }));
    }, 1500);
    // **New: Save progress every 30 seconds**
    const intervalId = setInterval(async () => {
      /////////////////////////
      // if (!saveProgress) { // Avoid saving while the progress modal is open
      //   try {
      //     console.log(data.state)
      //     const PostBody = createPostBody(formData);
      //     const formattedData = restructureAnswers(config, PostBody);
      //     const structured = {
      //       user_id: data.state.UserID ? data.state.UserID : '',
      //       page_number: "page2",
      //       responses: formattedData,
      //       action: "save",
      //       customer_id: data.state.customerID
      //     };
      //     await post('/survey/save', structured);
      //     console.log('Progress saved successfully in interval!');
      //   } catch (error) {
      //     console.error('Error saving progress in interval:', error);
      //   }
      // }
      /////////////////////////
    }, 30000); // Every 30 seconds

    return () => clearInterval(intervalId);
  }, [data.state, saveProgress]);

  const findTotal = (data) => {
    return data.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue ? currentValue : 0);
    }, 0);
  };
  const FindSection1WholesalePercentage = (updatedFormData) => {



    let spPercentage =
      (updatedFormData["CP_section1_wholesalepercentage"] / 100) *
      updatedFormData["CP_totalsale_section1_2024"];



    let BiologicPercentage =
      (updatedFormData["Biologic_section1_wholesalepercentage"] / 100) *
      updatedFormData["Biologic_totalsale_section1_2024"];

    let PSRPercentage =
      (updatedFormData["PSR_section1_wholesalepercentage"] / 100) *
      updatedFormData["PSR_totalsale_section1_2024"];
    let AllSeedPercentage =
      (updatedFormData["AllSeed_section1_wholesalepercentage"] / 100) *
      updatedFormData["AllSeed_totalsale_section1_2024"];
    let FertPercentage =
      (updatedFormData["solubleFertilizer_section1_wholesalepercentage"] /
        100) *
      updatedFormData["solubleFertilizer_totalsale_section1_2024"];
    let bulkFertPercentage =
      (updatedFormData["bulkFertilizer_section1_wholesalepercentage"] / 100) *
      updatedFormData["bulkFertilizer_totalsale_section1_2024"];

    let total = findTotal([
      spPercentage,
      BiologicPercentage,
      PSRPercentage,
      AllSeedPercentage,
      FertPercentage,
      bulkFertPercentage,
    ]);

    return updatedFormData["calculated_totalsale_2024"]
      ? ((total / updatedFormData["calculated_totalsale_2024"]) * 100).toFixed(2)
      : "";
  };

  const handleChange = (identifier, value) => {
    let updatedFormData = {};
    if (typeof value === "object") {
      updatedFormData = { ...formData, [identifier]: value.value };
    } else {
      updatedFormData = { ...formData, [identifier]: value };
    }

    const calculateFieldSum = (fieldKey, resultKey) => {
      const sum = Object.keys(updatedFormData).reduce(
        (acc, key) =>
          key.includes(fieldKey) ? acc + (updatedFormData[key] ?? 0) : acc,
        0
      );
      updatedFormData[resultKey] = sum;
    };

    if (identifier.includes("section1_2023")) {
      console.log("1")
      calculateFieldSum("section1_2023", "calculated_totalsale_2023");
    } else if (identifier.includes("section1_2024")) {
      console.log("2")
      calculateFieldSum("section1_2024", "calculated_totalsale_2024");
      updatedFormData["calculated_wholesalepercentage"] =
        FindSection1WholesalePercentage(updatedFormData);
    } else if (identifier.includes("section1_wholesalepercentage")) {
      console.log("3")
      updatedFormData["calculated_wholesalepercentage"] =
        FindSection1WholesalePercentage(updatedFormData);
    } else if (identifier.includes("company_top_")) {


      let othercompanysales = 
      updatedFormData["company_top_nametop1total"]+
      updatedFormData["company_top_nametop2total"]+
      updatedFormData["company_top_nametop3total"]+
      updatedFormData["company_top_nametop4total"]+
      updatedFormData["company_top_nametop5total"]+
      updatedFormData["bayertotal2024"]

      updatedFormData["othercompaniesaggregatedtotal"] =  updatedFormData["CP_totalsale_section1_2024"] - othercompanysales;
      
      updatedFormData["totalcompaniesaggregated"] = othercompanysales;
    } 
    
    else if (identifier.includes("bayertotal2024")) {
      let othercompanysales = 
      updatedFormData["company_top_nametop1total"]+
      updatedFormData["company_top_nametop2total"]+
      updatedFormData["company_top_nametop3total"]+
      updatedFormData["company_top_nametop4total"]+
      updatedFormData["company_top_nametop5total"]+
      updatedFormData["bayertotal2024"]

      updatedFormData["othercompaniesaggregatedtotal"] =  updatedFormData["CP_totalsale_section1_2024"] - othercompanysales;
      
      updatedFormData["totalcompaniesaggregated"] = othercompanysales;
    }
    
    
    else if (identifier.includes("_RICE1")) {
      console.log(updatedFormData["retailsalesrice_RICE1_totalsale"] ,
        updatedFormData["retailvolumerice_RICE1"])
      let sum =
        updatedFormData["retailsalesrice_RICE1_totalsale"] /
        updatedFormData["retailvolumerice_RICE1"];



      updatedFormData["averagendp1"] = sum;

      let OtherSum =
        updatedFormData["paddyybrid_retail"] -
        (updatedFormData["retailsalesrice_RICE1_totalsale"] +
          updatedFormData["retailsalesrice_RICE2_totalsale"] +
          updatedFormData["retailsalesrice_RICE3_totalsale"] +
          updatedFormData["bayerricetotal2024_BayerRICE_totalsale"]);

      updatedFormData["otherRetailRice"] = OtherSum;
    } else if (identifier.includes("_RICE2")) {
      console.log("7")
      let sum =
        updatedFormData["retailsalesrice_RICE2_totalsale"] /
        updatedFormData["retailvolumerice_RICE2"];
      updatedFormData["averagendp2"] = sum;

      let OtherSum =
        updatedFormData["paddyybrid_retail"] -
        (updatedFormData["retailsalesrice_RICE1_totalsale"] +
          updatedFormData["retailsalesrice_RICE2_totalsale"] +
          updatedFormData["retailsalesrice_RICE3_totalsale"] +
          updatedFormData["bayerricetotal2024_BayerRICE_totalsale"]);

      updatedFormData["otherRetailRice"] = OtherSum;
    } else if (identifier.includes("_RICE3")) {
      console.log("8")
      let sum =
        updatedFormData["retailsalesrice_RICE3_totalsale"] /
        updatedFormData["retailvolumerice_RICE3"];
      updatedFormData["averagendp3"] = sum;

      let OtherSum =
        updatedFormData["paddyybrid_retail"] -
        (updatedFormData["retailsalesrice_RICE1_totalsale"] +
          updatedFormData["retailsalesrice_RICE2_totalsale"] +
          updatedFormData["retailsalesrice_RICE3_totalsale"] +
          updatedFormData["bayerricetotal2024_BayerRICE_totalsale"]);

      updatedFormData["otherRetailRice"] = OtherSum;
    } else if (identifier.includes("_BayerRICE")) {
      console.log("9")
      let sum =
        updatedFormData["bayerricetotal2024_BayerRICE_totalsale"] /
        updatedFormData["bayerricewretailvolume_BayerRICE"];
      updatedFormData["averagendp_BayerRICE"] = sum;

      let OtherSum =
        updatedFormData["paddyybrid_retail"] -
        (updatedFormData["retailsalesrice_RICE1_totalsale"] +
          updatedFormData["retailsalesrice_RICE2_totalsale"] +
          updatedFormData["retailsalesrice_RICE3_totalsale"] +
          updatedFormData["bayerricetotal2024_BayerRICE_totalsale"]);
      updatedFormData["otherRetailRice"] = OtherSum;

      updatedFormData["totalRetailRice"] =
        updatedFormData["paddyybrid_retail"] +
        updatedFormData["bayerricetotal2024_BayerRICE_totalsale"];
      updatedFormData["totalRetailRiceVolume"] =
        updatedFormData["retailvolumerice_RICE3"] +
        updatedFormData["retailvolumerice_RICE2"] +
        updatedFormData["retailvolumerice_RICE1"] +
        updatedFormData["bayerricewretailvolume_BayerRICE"];
    } else if (identifier.includes("_totalsale")) {
      console.log("10")
      const retailKey = identifier.replace("_totalsale", "_retail");
      let total2024Key = "CP_totalsale_section1_2024";

      if(identifier.includes("SEE_")){
        console.log("CAME INSIDE", updatedFormData['AllSeed_totalsale_section1_2024'])
        total2024Key = "AllSeed_totalsale_section1_2024";
      }

      updatedFormData[retailKey] =
        ((updatedFormData[total2024Key] ?? 0) * (value / 100)).toFixed(2);
        
    } else if (identifier.includes("_wholesalepercentage")) {
      
      console.log("11")
      updatedFormData["totalwholesalesseedin2024"] =
        calculateWholeSale(updatedFormData);
    }

    setFormData(updatedFormData);
  };
  const validateFields = () => {
    const errors = {};
    config.forEach((field) => {
      if (field.sectionquestions) {
        field.sectionquestions.map((questions) => {
          questions.questions.map((exactelement) => {
            const { identifier, isOptional } = exactelement;
            if (!isOptional && !formData[identifier]) {
              errors[identifier] = true;
            }
          });
        });
      } else {
        field.questions.map((exactelement) => {
          const { identifier, isOptional } = exactelement;
          if (!isOptional && !formData[identifier]) {
            errors[identifier] = true;
          }
        });
      }
    });

    if (Object.keys(errors).length !== 0) {
      setShowError(true);
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const moveToStep3 = () => {
    if (validateFields()) {
      setReviewInfo(true);
    }
  };
  const close = () => {
    setReviewInfo(false);
  };

  const createPostBody = (selectedAnswers) => {
    let updatedPostBody = [];

    config.forEach((field) => {
      if (field.sectionquestions) {
        field.sectionquestions.map((questions) => {
          questions.questions.map((exactelement) => {
            updatedPostBody.push({
              qID: exactelement.qID,
              answer: formData[exactelement.identifier],
              identifier: exactelement.identifier,
            });
          });
        });
      } else {
        field.questions.map((exactelement) => {
          updatedPostBody.push({
            qID: exactelement.qID,
            answer: formData[exactelement.identifier],
            identifier: exactelement.identifier,
          });
        });
      }
    });

    return updatedPostBody;
  };

  const saveProgresses = async () => {
    setReviewInfo(false);
    let PostBody = createPostBody(formData);
    // Example usage:
    const formattedData = restructureAnswers(config, PostBody);
    const structured = {
      user_id: data.state.UserID ? data.state.UserID : "",
      page_number: "page2",
      responses: formattedData,
      action: "save",
      customer_id: data.state.customerID,
    };

    post("/survey/save", structured)
      .then((response) => {
        navigate(
          `/?territory_id=${data.state.territory_id}&fo_id=${data.state.UserID}`
        );
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const proceed = () => {
    setReviewInfo(false);
    let PostBody = createPostBody(formData);

    console.log(data.state);

    const formattedData = restructureAnswers(config, PostBody);
    const structured = {
      user_id: data.state.parsedParameters
        ? data.state.parsedParameters.user_id
        : "",
      page_number: "page2",
      responses: formattedData,
      action: "save",
      customer_id: data.state.customerID,
    };
    post("/survey/save", structured)
      .then((response) => {
        navigate("/step3", {
          state: {
            parsedParameters: data.state.parsedParameters,
            customerID: data.state.customerID,
            user_id: data.state.user_id,
            territory_id: data.state.territory_id,
          },
        });
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const renderField = (question, questionIndex) => {
    const {
      type,
      fieldName,
      identifier,
      placeholder,
      isHalf,
      isFirst,
      disabled,
      options,
      autopopulateColumn,
      isPercentage,
    } = question;

    const hasError = fieldErrors[identifier];

    switch (type) {
      case "text":
        return (
          <div
            key={identifier}
            className="form-group"
            style={
              isHalf
                ? {
                    width: "47%",
                    height: 93,
                    float: isFirst ? "left" : "right",
                    paddingRight: isFirst ? "3%" : "0%",
                    paddingLeft: isFirst ? "0%" : "3%",
                    opacity: disabled ? 0.3 : 1,
                    pointerEvents: disabled ? "none" : "all",
                  }
                : Style.fieldContainer
            }
          >
            <label style={Style.fieldName}>{fieldName}</label>

            <input
              type="text"
              placeholder={placeholder}
              value={formData[identifier] || ""}
              onChange={(e) => {
                let inputvalue = e.target.value.replace(/\D/g, "");
                if (isPercentage) {
                  if (inputvalue <= 100) {
                    handleChange(
                      identifier,
                      parseInt(e.target.value.replace(/\D/g, ""))
                    );
                  }
                } else {
                  handleChange(
                    identifier,
                    parseInt(e.target.value.replace(/\D/g, ""))
                  );
                }
              }}
              style={
                autopopulateColumn
                  ? {
                      ...Style.fieldElements,
                      pointerEvents: autopopulateColumn ? "none" : "all",
                      background: autopopulateColumn ? colors.Grey : "#fff",
                    }
                  : hasError
                  ? { ...Style.fieldElements, border: "1px solid red" }
                  : Style.fieldElements
              }
            />
            {hasError && (
              <span
                style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}
              >
                This is a required field!
              </span>
            )}
          </div>
        );
      case "textWithUnit":
        return (
          <div
            key={identifier}
            className="form-group"
            style={
              isHalf
                ? {
                    width: "47%",
                    height: 93,
                    float: isFirst ? "left" : "right",
                    paddingRight: isFirst ? "3%" : "0%",
                    paddingLeft: isFirst ? "0%" : "3%",
                    opacity: disabled ? 0.3 : 1,
                    pointerEvents: disabled ? "none" : "all",
                  }
                : Style.fieldContainer
            }
          >
            <label style={Style.fieldName}>{fieldName}</label>
            <TextInputWithUnit
              placeholder={placeholder}
              currentvalue={formData[identifier] || ""}
              onChange={(e) => handleChange(identifier, e)}
              options={options}
              hasError={hasError}
            />
            {hasError && (
              <span
                style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}
              >
                This is a required field!
              </span>
            )}
          </div>
        );
      case "singleSelect":
        return (
          <SingleSelect
            identifier={identifier}
            isHalf={isHalf}
            isFirst={isFirst}
            fieldName={fieldName}
            formData={formData}
            handleChange={handleChange}
            placeholder={placeholder}
            options={options}
            disabled={false}
            hasError={hasError}
          />
        );
      default:
        return null;
    }
  };

  const renderSection = (section) => {
    return (
      <>
        <div
          key={section.questionID}
          style={{
            marginBottom:
              section.questionID === 9 ||
              section.questionID === 2 ||
              section.questionID === 7
                ? 117
                : 0,
          }}
          className="section"
        >
          <h4 style={{ position: "sticky", top: 0, background: colors.White }}>
            {section.questionID}. {section.sectionHead}
          </h4>
          {section.questionID === 2 && (
            <HeighlightBar typeOfsale={"Crop Protection"} value={formData} />
          )}
          {section.questionID === 3 && (
            <HeighlightBar typeOfsale={"Seeds"} value={formData} />
          )}
          {section.questionID === 9 && (
            <HeighlightBar typeOfsale={"Rice(Hybrid Seed)"} value={formData} />
          )}
          {section.questions &&
            section.questions.map((question, index) =>
              renderField(question, index)
            )}

          {section.sectionquestions &&
            section.sectionquestions.map((subsection, subIndex) => (
              <div key={subIndex} style={{ marginBottom: 20 }}>
                {!(data.state.bayerSeller=="no" &&  subsection.subhead=="Bayer") &&  <>
                  <span style={Style.subhead}>{subsection.subhead}</span>
                  {subsection.questions.map((question, index) =>
                    renderField(question, index)
                  )}
                </>}

               
                
              </div>
            ))}

          {section.havingtaileend &&
            section.havingtaileend.questions.map((question, index) =>
              renderField(question, index)
            )}
        </div>

        {/* <div style={Style.sectionSeperator}></div> */}
      </>
    );
  };
  const showSaveProgress = () => {
    setSaveProgress(true);
  };

  return (
    <div>
      {showError && (
        <InfoPopUp
          close={() => {
            setShowError(false);
          }}
          heading={"All fields Mandatory*"}
          text={"Please fill all those fields."}
        />
      )}


      {reviewInfo && <ProceedNotification close={close} proceed={proceed} />}
      {saveProgress && (
        <SaveProgress
          close={() => {
            setSaveProgress(false);
          }}
          saveprogress={() => {
            saveProgresses();
            navigate(
              `/?territory_id=${data.state.territoryID}&fo_id=${data.state.UserID}`
            );
          }}
        />
      )}
      <Header
        header={Constants.retailer_survey}
        subheader={Constants.step2}
        showSaveProgress={showSaveProgress}
      />
      <div style={Style.container}>
        {config.map((section) => renderSection(section))}
      </div>
      <SaveAndCont onContinue={moveToStep3} saveProgress={saveProgresses} />
    </div>
  );
};
