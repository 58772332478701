import { useNavigate } from "react-router-dom"
import { Constants } from "../constants/textconstants"
import { Styles } from "../styles/newSurvey"

export const NewSurvey = ({userID, territoryID}) => {
    console.log(territoryID)
    const navigate  = useNavigate();
    return(
        <div style={Styles.container} onClick={()=>{
            navigate('/otp', {state:{newuser:true, parsedParameters:{user_id:userID, territory_id:territoryID, territoryID:territoryID}, territoryID: territoryID}})
        }}>
            <i class="fa fa-plus" style={Styles.plusIcon} aria-hidden="true"></i>
            <span style={Styles.surveyText}>{Constants.new_survey}</span>
        </div>
    )
}