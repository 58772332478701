import { useEffect, useState } from "react";
import { ActionButton } from "../components/button";

import { Header } from "../components/headerTypeOne";
import { SimpleTextBox } from "../components/normalTextBox";
import { StickyActionContainer } from "../components/stickyByttonWithConcent";

import { Constants } from "../constants/textconstants";
import { Style } from "../styles/otpverification";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../store/slices/util";
import { post, VerifyOTPs } from "../config/api";

export const VerifyOTP = () => {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [timer, setTimer] = useState(60); // Initial timer value (60 seconds)
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [otp, setOTP] = useState("");
  const[loader,setLoader] = useState(false)

  const data = useLocation();
  const navigate = useNavigate();
  const phoneNumber = data.state.phoneNumber;
  const retailer = data.state.retailer;
  const dispatch = useDispatch();
  const userID = data.state.userID;
  const territoryID =  data.state.territoryID;
  console.log("RETAILER IS PASSED = ", retailer)
  useEffect(() => {
    dispatch(
      showToast({ toast: true, toastmessage: "OTP sent successfully!" })
    );
    setTimeout(() => {
      dispatch(showToast({ toast: false, toastmessage: "" }));
    }, 1500);
  }, []);

  useEffect(() => {
    let countdown;

    // Start countdown if timer > 0
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      // Timer reached 0, show the button
      setIsButtonVisible(true);
      clearInterval(countdown);
    }
    // Cleanup interval on unmount
    return () => clearInterval(countdown);
  }, [timer]);

  const resendOTPHandler = () => {
    const dataa = {
      user_id: userID,
      mobile_number: data.state.phoneNumber,
    };

    post("/otp/send", dataa)
    .then((response) => {
    if(response)
      console.log(response)
      setLoader(false);
        dispatch(
          showToast({ toast: true, toastmessage: "OTP re-sent successfully!" })
        );
        setTimeout(() => {
          dispatch(showToast({ toast: false, toastmessage: "" }));
        }, 1500);
    })
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      setLoader(false);
      window.alert("Something went wrong!")
      console.error("Error fetching data:", error);
    });




    // Logic for resending OTP
    setTimer(60); // Reset timer
    setIsButtonVisible(false); // Hide button again
  };

  const handler = (data) => {
    const regx = /^\d{4}$/;
    setEnableSubmit(regx.test(data));
    setOTP(data);
  };
  return (
    <div>
      <Header
        header={Constants.retailer_survey}
        subheader={Constants.otp_verification}
      />
      <div style={Style.container}>
        <span style={Style.infoText}>{Constants.enter_otp + phoneNumber}</span>
        <SimpleTextBox
          placeholder={Constants.enter_otp_here}
          onChange={handler}
        />
        <span style={Style.infoTexrtSub}>
          {Constants.didnt_otp}{" "}
          <mark
            style={Style.resend}
            onClick={() => {
              if(isButtonVisible){
                resendOTPHandler();
              }
              
            }}
          >
            {" "}
            {!isButtonVisible ? `00 :${timer}` : Constants.resend_OTP}
          </mark>
        </span>
      </div>
      <StickyActionContainer>
        <ActionButton
          onPress={() => {
            //code for submitting OTP, and on succeffull generation redirect
            const data1 = {
              user_id: userID,
              mobile_number: phoneNumber,
              otp: otp,
            };
            setLoader(true);
            post("/otp/verify", data1)
              .then((response) => {
                if(response.error){
                  window.alert("wrong OTP!")
                  return;
                }
                setLoader(false);
                console.log(territoryID, "passed2")
                navigate("/step1", {
                  state: {
                    isNewUser: data.state.isNewUser,
                    retailer: retailer,
                    userID: userID,
                    territoryID:territoryID,
                    createNewUser: data.state.creatingUser,
                    mobile_number:phoneNumber
                  },
                });
              })
              .then((data) => {
                console.log(data);
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });
          }}
          text={Constants.submit}
          loader={loader}
          isEnabled={enableSubmit}
        />
      </StickyActionContainer>
    </div>
  );
};
