import { colors, Font } from "../styles/standards"

export const SaveAndCont = ({onContinue, saveProgress}) => {
    return(
        <div style={Style.container}>
            <div style={{...Style.button, color: colors.Blue_Heilight}} onClick={saveProgress}>Save Progress</div>
            <div  style={{...Style.button, background:colors.Blue_Heilight, color:colors.White}} onClick={onContinue}>Save & Continue</div>
        </div>
    )
}
const Style = {
    container:{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        padding: '5%',
        display:'flex',
        flexDirection:'row',
        backgroundColor: colors.White,
        justifyContent:'space-between'
    },
    button:{
        width:'45%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        paddingTop:15,
        paddingBottom:15,
        border: `1px solid ${colors.Blue_Heilight}`,
        ...Font.RobotoBold,
        fontSize: 14,
    }
}