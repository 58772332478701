import { colors, Font, Gap } from "./standards";

export const Styles = {
    container:{
        paddingTop:200
    },
    arrowContainer:{
        display:'flex',
        justifyContent:'space-between',
        flexDirection:'row'
    },
    syncnow:{
        ...Font.RobotoBold,
        color:colors.Blue_Heilight,
        fontSize:13,
        float:'right'
    },
    retailerRow:{
        padding: Gap.standardGap,
        borderBottom: '1px solid #efefef',
        position:'relative'
    },
    retailerName:{
        ...Font.RobotoBold,
        fontSize: 16,
        color: colors.Blue_Dark,
        display:'block',
        marginBottom: 10
    },
    area:{
        ...Font.RobotoMedium,
        fontSize: 14,
        color: colors.Blue_Dark,
        display:'block',
        marginBottom: 10
    },
    phoneNumber:{
        ...Font.RobotoMedium,
        fontSize: 14,
        color: colors.Blue_Dark,
        display:'block'
    },
    searchContainer:{
        padding: Gap.standardGap,
        paddingTop:0
    },
    statusCapsule:{
        ...Font.RobotoBold,
        fontSize: 12,
        color:colors.White,
        borderRadius:19,
        padding:'8px 19px',
        position:'absolute',
        bottom:20,
        right:20
    },
    filterCapsule:{
    border: '1px solid #c0c0c0',
    display: 'block',
    marginRight:10,
    padding: 9,
    textAlign: 'center',
    borderRadius: 11,
    ...Font.RobotoMedium,
    fontSize: 12,
    },
    filterCont:{
        display:'flex',
        flexDirection:'row',
        padding:'20px 0px'
    },
    totalCount:{
        ...Font.RobotoMedium,
    fontSize: 12,
    }
}