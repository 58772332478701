
import { useDispatch } from "react-redux"
import { Styles } from "../styles/subDistrict"
import { setSelectedFOT, setSelectedSubDistrict, setFOTValue } from "../store/slices/subDistricts";

export const SubDist = ({name, code, type, onClose}) => {
    const dispatch = useDispatch();
    const setSelectedSub= (name) => {
        if(type.toLowerCase().includes('fot')){
            dispatch(setSelectedFOT(name));
            dispatch(setFOTValue(code))
            dispatch(setSelectedSubDistrict(null));
        }else{
            dispatch(setSelectedSubDistrict(name));
        }
        onClose();
    }
    return(
        <div style={Styles.row} onClick={()=>{setSelectedSub(name)}}>
            {name}
            <span style={Styles.distCode}>{code}</span>
        </div>
    )
}