export const WrapperRow = ({ children, fieldTag }) => {
  return (
    <div style={style.container}>
      {children}
    </div>
  );
};
const style = {
  container: {
    display:'flex',
    flexDirection:'row'
  }
};
