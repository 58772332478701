import { colors, Font, Gap } from "./standards";

export const Style = {
    container:{
        padding: Gap.standardGap,
        paddingBottom:100
    },
    sectionSeperator:{
        width:'100%',
        height:15,
        background:colors.Grey,
        marginTop:20,
       
    },
    fieldElements:{
        boxSizing:'border-box',
        display: "block",
        width: "100%",
        padding: 15,
        marginTop:6,
        border:`1px solid ${colors.Grey_Dark}`,
        borderRadius:4
    },
    fieldName:{
        ...Font.RobotoMedium,
        fontSize: 14,
        color: colors.Blue_Dark,
        display:'block',
        marginBottom:10
        
    },
    fieldContainer:{
        padding: '10px 0px'
    },
    subhead:{
        ...Font.RobotoBold,
        fontSize: 14,
        color: colors.Blue_Heilight,
        display: 'block',
        marginBottom:10
    },
    radioContainer:{
        display:'flex',
        flexDirection:'column'
    },
    radiorow:{
        ...Font.RobotoMedium,
        fontSize: 14,
        color: colors.Blue_Dark,
        display:'block',
        marginTop:10
    },
    camera:{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:150,
        border:`2px dashed ${colors.Blue_Heilight}`,
        background:'#95caff',
        marginTop:20
    },
    toggleContainer:{
        display: 'flex',
        padding: '20px 10px',
        background: '#deebf7',
        flexDirection:'row'
    },
    Totalsale:{
        display:'block',
        ...Font.RobotoBold,
        fontSize: 14,
        color: colors.Black,
        marginBottom:10
    },
    toggleText:{
        ...Font.RobotoMedium,
        display:'block',
        padding:8
    }
}