import { Styles } from "../styles/headerwithSearch"
import { TextBoxWithIcon } from "./textBoxWithIcon"

export const HeaderWithSearch = ({heading, onChange, onBack, placeHolder}) => {
    return(
        <div>
            <div style={Styles.container} onClick={onBack}>
                <i class="fa fa-arrow-left" style={Styles.backIcon} aria-hidden="true"></i>
                <span style={Styles.heading}>{heading}</span>
            </div>
            <div style={Styles.searchContainer}>
                <TextBoxWithIcon iconname={"fa fa-search"} handler={onChange} placeHolder={placeHolder}/>
            </div>
        </div>
    )
}