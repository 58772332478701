import { colors, Font, Gap } from "./standards";

export const Style = {
    container:{
        padding:Gap.standardGap
    },
    infoText:{
        ...Font.RobotoBold,
        color:colors.Blue_Dark,
        fontSize: 17,
        lineHeight:1.4
    },
    infoTexrtSub:{
        ...Font.RobotoMedium,
        color:colors.Blue_Dark,
        fontSize: 14,
       marginTop:10,
       display:'block'
    },
    resend:{
        ...Font.RobotoBold,
        color:colors.Blue_Heilight,
        fontSize: 14,
        lineHeight:1.4,
        background: 'transparent'
    },
    shopname:{
        display:'block',
        padding:'10px 0px',
        color:colors.Blue_Heilight,
        ...Font.RobotoMedium
    },
    errormessage:{
        color: 'red',
        ...Font.RobotoMedium,
        fontSize:12,
        marginTop:10,
        display:'block'
    }
}