export const SimpleTextBox = ({placeholder, onChange}) => {
    return(
        <div>
            <input type="text" style={Style.textbox} placeholder={placeholder} onChange={(e)=>{onChange(e.target.value)}}/>
        </div>
    )
}
const Style = {
    textbox:{
        width:'100%',
        padding:15,
        boxSizing: 'border-box',
        marginTop:10
    }
}