import React, { useState } from "react";
import { Styles } from "../styles/subDistrict";
import { HeaderWithSearch } from "../components/headeeWithSearch";
import { SubDist } from "../components/subdistrict";

export const SearchableModal = ({ data, loading, onClose, texts }) => {
  const [subDistricts, setSubDistricts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const handler = (e) => {
    setSearchText(e);
    setSubDistricts(
      data.filter(
        (item) =>
          item[Object.keys(item)[0]].toLowerCase().includes(e) ||
          item[Object.keys(item)[1]].toLowerCase().includes(e)
      )
    );
  };

  const dataToRender = searchText === "" ? data : subDistricts;
  return (
    <div style={Styles.overLay}>
      <div style={Styles.fixedHeader}>
        <HeaderWithSearch
          heading={texts.heading}
          onChange={handler}
          onBack={onClose}
          placeHolder={texts.placeholder}
        />
      </div>
      <div style={Styles.subDistBody}>
        {dataToRender &&
          dataToRender.map((item) => {
            return (
              <SubDist
                name={item[Object.keys(item)[0]]}
                code={item[Object.keys(item)[1]]}
                type={Object.keys(item)[1]}
                onClose={onClose}
              />
            );
          })}
      </div>
    </div>
  );
};
