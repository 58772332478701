import { colors } from "../styles/standards";


export const TextBoxWithIcon = ({ iconname, handler, placeHolder, value, isDisabled }) => {
  return (
    <div style={isDisabled? style.disabledContainer:style.container}>
      <i class={iconname} style={style.icon}></i>
      <input
        placeholder={placeHolder}
        value={value}
        onChange={(e)=> handler(e.target.value.toLowerCase())}
        style={style.inputBox}
        defaultValue={value}
        type="text"
      />
    </div>
  );
};
const style = {
  container: {
    border: "1px solid #767676",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    width: "100%",
    paddingLeft: 10,
    paddingRight: 20,
    marginTop: 10,
    boxSizing: "border-box",
    borderRadius: 5,
  },
  disabledContainer:{
    border: "1px solid #767676",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    width: "100%",
    paddingLeft: 10,
    paddingRight: 20,
    marginTop: 10,
    boxSizing: "border-box",
    borderRadius: 5,
    pointerEvents:'none',
    background:'#efefef'
  },
  icon: {
    fontSize: 20,
    marginRight: 10,
    color:colors.Blue_Dark
  },
  inputBox: {
    width: "100%",
    height: 40,
    border: "none",
    outline: "none",
    background:'transparent'
  },
};
