export const Constants = {
    retailer_survey: 'Retailer Survey',
    new_survey: 'New Survey',
    sub_dist: 'Sub District',
    search_sub: "Search Sub - District",
    search_by_name_number: "Search By Name OR Number",
    otp_verification:'OTP Verification',
    send_sms: `We will send an SMS to verify participant's mobile number`,
    otp_proceed: "Please proceed with OTP verification to start the survey",
    concent: 'I confirm that i have concent of the participant for OTP verification and survey.',
    request_otp:'Request OTP',
    enter_otp: 'Please enter 4 digit OTP sent via SMS on ',
    enter_otp_here: 'Enter OTP here.',
    submit: 'Submit',
    didnt_otp:`Didn't get the OTP?`,
    resend_OTP: 'Resend OTP' ,
    step1: "Step 1 of 3 - Personal Details",
    step2: "Step 2 of 3 - RWDS",
    step3: "Final step - Market",
    fot: "FOT",
    enter:'Enter 10 Digit Phone Number',
    tenDigits: "Please enter 10 digits phone number"
}
export const subDist = {
    heading: 'Search Sub - Districts',
    placeholder: 'Search Sub - Districts'
}

export const FOT = {
    heading: 'Search FOT',
    placeholder: 'Search FOT'
}