import { useEffect, useState } from "react";
import { Check } from "../constants";
import { Font } from "../styles/standards";
import { useSpring, animated } from "react-spring";

export const SurveyCompleted = () => {
  const [isAnimated, setIsAnimated] = useState(false);

  const props = useSpring({
    from: { opacity: 0, scale: 0.8 },
    to: { opacity: 1, scale: 1 },
    delay: 200, // Delay the animation
  });

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 9999999,
      }}
    >
      <animated.div style={props}>
        <img src={Check} alt="Animated Image" style={{ width: 100, height: 100 }}  />
      </animated.div>
      <span
        style={{
          display: "block",
          width: "80%",
          textAlign: "center",
          marginTop: 20,
          ...Font.RobotoBold,
        }}
      >
        Survey Completed.
      </span>
      <span
        style={{
          display: "block",
          width: "80%",
          textAlign: "center",
          marginTop: 20,
          ...Font.RobotoMedium,
        }}
      >
        Thank you for participating.
      </span>
    </div>
  );
};
