import { useLocation, useNavigate } from "react-router-dom";
import { Style } from "../styles/header"

export const Header = ({header, subheader, parsedParameters, showSaveProgress}) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    return(
        <div style={Style.typeonecontainer}>
            <div onClick={()=>{
                if(location.pathname==='/step1' ||location.pathname==='/step2' ){
                    showSaveProgress()
                    // navigate(`/?territory_id=${parsedParameters.territory_id}&user_id=${parsedParameters.user_id}`)
                }else{
                    navigate(-1)
                }
            }}>
            <i class="fa fa-arrow-left" style={Style.back}></i>
            </div>
            <div>
            <span style={Style.heading}>{header}</span>
            <span style={Style.seperator}></span>
            <span style={Style.subheading}>{subheader}</span>
            </div>
        </div>
    )
}