import { useState } from "react";
import { colors, Font } from "../styles/standards";
import { Constants } from "../constants/textconstants";
import { ActionButton } from "./button";
import { useNavigate } from "react-router-dom";
import { post, SendOTP } from "../config/api";
import { useAppContext } from "../AppContext";
import { InfoPopUp } from "./infoPopuop";
import { useSelector } from "react-redux";

const Selected = () => {
  return (
    <div style={Style.Selected}>
      <i class="fa fa-check"></i>
    </div>
  );
};

const NotSelected = () => {
  return <div style={Style.NotSelected}></div>;
};
export const Concent = ({
  phoneNumber,
  isNewUser,
  retailer,
  oldPhone,
  userID,
  territoryID,
  creatingUser
}) => {
  const [selected, setSelected] = useState(true);
  const navigate = useNavigate();
  const toggleSelection = () => {
    setSelected(!selected);
  };
  const [loader, setLoader] = useState(false);
  const [showExisting, setShowExisting] =useState(false);
  console.log("RETAILER IS PASSED = ", retailer, 'territoryID = ', territoryID)
  const retailers = useSelector((state) => state.retailers.retailers
);
  const requestOTP = () => {
    // const found = retailers.some(el => el.mobile_number === phoneNumber);
    // if (!found){
      setLoader(true);
      const data = {
        user_id: userID,
        mobile_number: phoneNumber,
      };
      if (oldPhone !== phoneNumber) {
        data["old_mobile_number"] = oldPhone;
      }
      post("/otp/send", data)
        .then((response) => {
        if(response)
          setLoader(false);
          navigate("/verify_otp", {
            state: {
              phoneNumber: phoneNumber,
              isNewUser: isNewUser,
              retailer:retailer,
              userID: userID,
              territoryID:territoryID,
              creatingUser:creatingUser
            },
          });
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          setLoader(false);
          window.alert("Something went wrong!")
          console.error("Error fetching data:", error);
        });
    // }else{
    //   setShowExisting(true)
    // }
  }
  return (
    <>
    {showExisting && <InfoPopUp close={()=>{setShowExisting(false)}} heading={"User Exists!"} text={"This user is already existing in our list of retailers, please go to home page and search wth the phone number"}/>}
      {/* <div onClick={toggleSelection} style={{...Style.container, pointerEvents:phoneNumber && phoneNumber.length===10?'all':'none'}}>
        {selected ? <Selected /> : <NotSelected />}
        <span style={Style.concent}>{Constants.concent}</span>
      </div> */}
      <ActionButton
        onPress={() => {
          requestOTP();
        }}
        text={Constants.request_otp}
        loader={loader}
        isEnabled={selected}
      />
    </>
  );
};

const Style = {
  Selected: {
    height: 25,
    width: 25,
    background: colors.Blue_Heilight,
    borderRadius: 3,
    color: colors.White,
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${colors.Blue_Heilight}`,
  },
  NotSelected: {
    height: 25,
    width: 25,
    border: `1px solid ${colors.Blue_Dark}`,
    borderRadius: 3,
  },
  concent: {
    ...Font.RobotoMedium,
    color: colors.Blue_Dark,
    fontSize: 13,
    display: "block",
    marginLeft: 10,
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
};
