import { Style } from "../styles/step1";
import Webcam from "react-webcam";

import "../components/ToggleSwitch.css";

import React, { useEffect, useState } from "react";
import { colors, Font } from "../styles/standards";

export const SingleSelect = ({
  identifier,
  isHalf,
  isFirst,
  fieldName,
  formData,
  handleChange,
  placeholder,
  options,
  disabled,
  hasError,
  autopopulateColumn
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  if(identifier==='customerType' && formData['businesspartner']=='yes'){
         options = [{id: 1, value: 'Distributor'},{id: 2, value: 'Retailer'},{id: 3, value: 'FPO'},{id: 4, value: 'BLF'},{id: 5, value: 'Cooperative Socities/Govt Bodies'},{id: 6, value: 'e-retailer'}];
  }
  if(identifier==='customerType' && formData['businesspartner']=='no'){
    options = [{id: 2, value: 'Retailer'},{id: 3, value: 'FPO'},{id: 5, value: 'Cooperative Socities/Govt Bodies'},{id: 6, value: 'e-retailer'}];
}

  const filteredOptions =
    searchTerm === ""
      ? options
      : options.filter((option) =>
          option.value.toLowerCase().includes(searchTerm.toLowerCase())
        );

  const handleSelect = (value) => {
    handleChange(identifier, value);
    setSearchTerm("")
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div
      key={identifier}
      style={
        isHalf
          ? {
              width: "47%",
              height: 93,
              float: "left",
              paddingRight: isFirst ? "3%" : "0%",
              paddingLeft: isFirst ? "0%" : "3%",
              opacity: disabled ? 0.3 : 1,
              pointerEvents: disabled ? "none" : "all",
            }
          : Style.fieldContainer
      }
    >
      <label style={Style.fieldName}>{fieldName}<mark style={{color:'red', background:'#fff'}}>*</mark></label>
      <div
        style={{
          ...Style.fieldElements,
          position: "relative",
          cursor: disabled || autopopulateColumn ? "not-allowed" : "pointer",
          pointerEvents: autopopulateColumn? 'none':'all',
          background: autopopulateColumn? colors.Grey :'#fff'
        }}
        onClick={() => (disabled ? null : setIsOpen(!isOpen))}
      >
        {formData[identifier] || placeholder}
        <i
          className="fa fa-angle-down"
          style={{
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        ></i>
      </div>
      {hasError && <span style={{...Font.RobotoMedium, color:'red', fontSize:11}}>This is a required field!</span>}
      
      {isOpen && (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex:9
          }}
          className="test2"
        >
          <div
            style={{
              background: "rgba(0, 0, 0, 0.3)",
              width:  "80%",
              zIndex: 1,
              padding: 10,
              borderRadius: 4,
              backgroundColor: "white",
              maxHeight: 350,
              overflow: "scroll",
            }}
            className="test1"
          >
            {/* {searchTerm !== "" && ( */}
              <input
                type="text"
                placeholder="Search..."
                style={{ width: "100%", marginBottom: 10, height: 48,
                  border: 'none',
                  outline: 'none' }}
                onChange={handleSearch}
              />
         
            {filteredOptions.length > 0 ? (
              <ul style={{ listStyle: "none", padding: 0,height:filteredOptions.length>10?254:'auto', overflow:'scroll',margin:0 }}>
                {filteredOptions.map((option) => (
                  <li
                    key={option.id}
                    style={{ padding: "10px", cursor: "pointer" }}
                    onClick={() => handleSelect(option)}
                  >
                    {option.value}
                  </li>
                ))}
              </ul>
            ) : (
              <span>No results found.</span>
            )}
            <div style={{borderTop:'1px solid #efefef', display:'flex', alignItems:'center', justifyContent:'center', background:'#efefef', padding:10}} onClick={handleCancel}>
                <span>
                  Cancel
                </span>
              </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const TextDate = ({
  identifier,
  fieldName,
  type,
  placeholder,
  formData,
  handleChange,
  hasError,
}) => {
  
  return (
    <div key={identifier} style={Style.fieldContainer}>
      <label style={Style.fieldName}>{fieldName}<mark style={{color:'red', background:'#fff'}}>*</mark></label>
      <input
        type={type}
        placeholder={placeholder}
        value={formData[identifier] || ""}
        onChange={(e) => {
          if(identifier=='pin' || identifier==='dob'){
            handleChange(identifier, e.target.value.replace(/\D/g, ''))
          }else{
            handleChange(identifier, e.target.value)
          }
          
        }}
        style={
          hasError
            ? { ...Style.fieldElements, border: "1px solid red" }
            : identifier==="mobile_number"? {...Style.fieldElements, pointerEvents:'none', backgroundColor:'#efefef'} :{...Style.fieldElements}
        }
      />
      {hasError && <span style={{...Font.RobotoMedium, color:'red', fontSize:11}}>This is a required field!</span>}
      {identifier==="email" ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData[identifier])?"":<span style={{...Font.RobotoMedium, color:'red', fontSize:11}}>Please enter a valid email!</span>:''}
      
    </div>
  );
};

export const RadioControl = ({
  identifier,
  fieldName,
  options,
  formData,
  handleChange,
  disabled,
  hasError
}) => {

  useEffect(()=>{
    handleChange(identifier,"Village" )
  },[])

  if (identifier === "radioSelection") {
    return (
      <div
        key={identifier}
        className="sliding-toggle-container"
        style={{
          ...Style.fieldContainer,
          marginTop: identifier === "radioSelection" ? 20 : 0,
          opacity: disabled ? 0.3 : 1,
          pointerEvents: disabled ? "none" : "all",
          float: "left",
          width: "100%",
        }}
      >
        <label
          className="field-label"
          style={{ ...Style.fieldName, marginBottom: 15 }}
        >
          {fieldName}
        </label>
        <div style={Style.toggleContainer}>
          <span style={Style.toggleText}>{options[0].value}</span>
          <div className="toggle-slider">
            {/* Slider background */}
            <div
              className="slider"
              style={{
                transform:
                  formData[identifier] === options[1]?.value
                    ? "translateX(100%)"
                    : "translateX(0)",
              }}
            ></div>

            {/* Radio buttons */}
            {options.map((option, i) => (
              <label
                key={option.id}
                className="toggle-label"
                htmlFor={`${identifier}-${i}`}
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  name={identifier}
                  id={`${identifier}-${i}`}
                  className="toggle-input"
                  value={option.value}
                  checked={formData[identifier] === option.value}
                  onChange={(e) => handleChange(identifier, e.target.value)} // Ensure onChange works
                />
              </label>
            ))}
          </div>
          <span style={Style.toggleText}> {options[1].value}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        key={identifier}
        style={
          identifier === "radioSelection"
            ? {
                ...Style.fieldContainer,
                marginTop: 20,
                opacity: disabled ? 0.3 : 1,
                pointerEvents: disabled ? "none" : "all",
              }
            : Style.fieldContainer
        }
      >
        <label style={Style.fieldName}>{fieldName}<mark style={{color:'red', background:'#fff'}}>*</mark></label>
      {hasError && <span style={{...Font.RobotoMedium, color:'red', fontSize:11}}>This is a required field!</span>}
        
        <div style={{display:'flex'}}>
          {options.map((option) => (
            <label
              key={option.id}
              style={{ display: "flex", margin: "5px 10px", flexDirection:'row' }}
            >
              <input
                type="radio"
                name={identifier}
                style={{height:23,width:23}}
                value={option.value}
                checked={formData[identifier] === option.value}
                onChange={(e) => handleChange(identifier, e.target.value)}
              />
              <span style={{display:'block', margin:'3px 10px'}}>{" "}{option.value}</span>
            </label>
          ))}
        </div>
      </div>
    );
  }
};

export const PhotoController = ({
  identifier,
  fieldName,
  image,
  clickToCapture,
  webcamRef,
  capturePhoto,
  setClickToCapture,
  setImage,
  formData
}) => {
  const videoConstraints = {
    facingMode: "environment"
  };

  // console.log(formData,'[[')
  return (
    <div key={identifier} style={Style.fieldContainer}>
      <label style={Style.fieldName}>{fieldName}<mark style={{color:'red', background:'#fff'}}>*</mark></label>
      {!image ? (
        <>
          {clickToCapture ? (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{ width: "100%", maxWidth: "500px" }}
                videoConstraints={videoConstraints}
              />
              <button onClick={capturePhoto}>Capture</button>
            </>
          ) : (
            <div
              style={Style.camera}
              onClick={() => {
                setClickToCapture(true);
              }}
            >
              <img src={formData['shopPhoto']} style={{height:100}} />
              <span>click to capture photo</span>
            </div>
          )}
        </>
      ) : (
        <>
          <img
            src={image}
            alt="Captured"
            style={{ width: "100%", maxWidth: "500px" }}
          />
          <button onClick={() => setImage(null)}>Retake</button>
        </>
      )}
    </div>
  );
};

export const Cordinates = ({
  identifier,
  fieldName,
  getLocation,
  formData,
  locationError,
}) => {
  useEffect(()=>{
    getLocation(identifier);
  },[])
  return (
    <div key={identifier} style={Style.fieldContainer}>
      <label style={Style.fieldName}>{fieldName}</label>
      {formData[identifier] && (
        <div>
          <p>Latitude: {formData[identifier].latitude}</p>
          <p>Longitude: {formData[identifier].longitude}</p>
        </div>
      )}
      {locationError && <p style={{ color: "red" }}>{locationError}</p>}
    </div>
  );
};
