import { companyNames } from "./statesanddistricts";

export const config = [
  {
    sectionHead:
      "Please provide category wise Annual sales (Retail + Wholesale) for the year 2023 and 2024",
    questionID: 1,
    sectionquestions: [
      {
        subhead: "Crop Protection",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: false,
            type: "textWithUnit",
            identifier: "CP_totalsale_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            qID:'s2q1'
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "CP_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q2'
          },
          {
            fieldName: "wholesale in 2024 (in %)",
            isOptional: false,
            type: "text",
            identifier: "CP_section1_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q3',
             isPercentage: true
          },
        ],
      },
      {
        subhead: "Biologic Pestisides(eg: Rhapsody)",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: false,
            type: "textWithUnit",
            identifier: "Biologic_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q4'
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "Biologic_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q5'
          },
          {
            fieldName: "wholesale in 2024 (in %)",
            isOptional: false,
            type: "text",
            identifier: "Biologic_section1_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q6',
             isPercentage: true
          },
        ],
      },
      {
        subhead: "PSR + Biosimulants, (eg. Ethral, Planofix, Ambition, biovita etc) ",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: false,
            type: "textWithUnit",
            identifier: "PSR_totalsale_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q7'
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "PSR_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q8'
          },
          {
            fieldName: "wholesale in 2024 (in %)",
            isOptional: false,
            type: "text",
            identifier: "PSR_section1_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q9',
             isPercentage: true
          },
        ],
      },
      {
        subhead: "All Seeds(including Veg, Hybrid, OPV)",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: false,
            type: "textWithUnit",
            identifier: "AllSeed_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q10'
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "AllSeed_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q11'
          },
          {
            fieldName: "wholesale in 2024 (in %)",
            isOptional: false,
            type: "text",
            identifier: "AllSeed_section1_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q12',
             isPercentage: true
          },
        ],
      },
      {
        subhead: "Soluble fertilizer (NPK)",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: false,
            type: "textWithUnit",
            identifier: "solubleFertilizer_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q13'
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "solubleFertilizer_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q14'
          },
          {
            fieldName: "wholesale in 2024 (in %)",
            isOptional: false,
            type: "text",
            identifier: "solubleFertilizer_section1_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q15',
             isPercentage: true
          },
        ],
      },
      {
        subhead: "Fertilizer Bulk",
        questions: [
          {
            fieldName: "Total sales in 2023",
            isOptional: false,
            type: "textWithUnit",
            identifier: "bulkFertilizer_section1_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q16'
          },
          {
            fieldName: "Total sales in 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "bulkFertilizer_totalsale_section1_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
             qID:'s2q17'
          },
          {
            fieldName: "wholesale in 2024 (in %)",
            isOptional: false,
            type: "text",
            identifier: "bulkFertilizer_section1_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q18',
             isPercentage: true
          },
        ],
      },
      {
        subhead: "Total Sales",
        questions: [
          {
            fieldName: "2023 in INR",
            isOptional: false,
            type: "text",
            identifier: "calculated_totalsale_2023",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            autopopulateColumn: true,
             qID:'s2q19'
          },
          {
            fieldName: "2024 in INR",
            isOptional: false,
            type: "text",
            identifier: "calculated_totalsale_2024",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q20'
          },
          {
            fieldName: "wholesale in 2024 (in %)",
            isOptional: false,
            type: "text",
            identifier: "calculated_wholesalepercentage",
            placeholder: "Input here",
            autopopulateColumn: true,
             qID:'s2q21'
          },
        ],
      },
    ],
    // havingtaileend: {
    //   questions: [
    //     {
    //       fieldName:
    //         "TotalSales In lakhs(fill this if categorywise detail is not available)",
    //       isOptional: false,
    //       type: "text",
    //       identifier: "totalsalesinlakhs",
    //       placeholder: "Input here",
    //     },
    //   ],
    // },
  },

  {
    sectionHead:
      "Please provide category wise details of Annual sales(Wholesale + Retail) of Crop Protection in the year 2024",
    questionID: 2,
    sectionquestions: [
      {
        subhead: "Insectiside",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: false,
            type: "text",
            identifier: "insectiside_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
             qID:'s2q22',
             isPercentage: true
          },
          {
            fieldName: "Retail Sales INR",
            isOptional: false,
            type: "text",
            identifier: "insectiside_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q23'
          }
        ],
      },
      {
        subhead: "Fungiside",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: false,
            type: "text",
            identifier: "fungiside_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
             qID:'s2q24',
             isPercentage: true
          },
          {
            fieldName: "Retail Sales INR",
            isOptional: false,
            type: "text",
            identifier: "fungiside_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q25'
          }
        ],
      },
      {
        subhead: "Herbiside",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: false,
            type: "text",
            identifier: "herbiside_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
             qID:'s2q26',
             isPercentage: true
          },
          {
            fieldName: "Retail Sales INR",
            isOptional: false,
            type: "text",
            identifier: "herbiside_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q27'
          }
        ],
      },
      {
        subhead: "Seed Treatment",
        questions: [
              {
                fieldName: "Total Sales(in %)",
                isOptional: false,
                type: "text",
                identifier: "seedtreatment_totalsale",
                placeholder: "Input here",
                isHalf: true,
            isFirst: true,
             qID:'s2q28',
             isPercentage: true
              },
              {
                fieldName: "Retail Sales INR",
                isOptional: false,
                type: "text",
                identifier: "seedtreatment_retail",
                placeholder: "Input here",
                isHalf: true,
                isFirst: false,
                autopopulateColumn: true,
             qID:'s2q29'
              }
        ],
      },
    ],
    // havingtaileend: {
    //   questions: [
    //     {
    //       fieldName:
    //         "TotalSales In lakhs(fill this if categorywise detail is not available)",
    //       isOptional: false,
    //       type: "text",
    //       identifier: "totalsalesinlakhscategory",
    //       placeholder: "Input here",
    //     },
    //   ],
    // },
  },
  {
    sectionHead:
      "Please provide category wise details of Annual sales(Wholesale + Retail) of Seeds of the year 2024",
    questionID: 3,
    sectionquestions: [
      {
        subhead: "Corn Hybrids",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: false,
            type: "text",
            identifier: "SEE_cornhybrid_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
             qID:'s2q30',
             isPercentage: true
          },
          {
            fieldName: "Total Sales(In Lakhs)",
            isOptional: false,
            type: "text",
            identifier: "SEE_cornhybrid_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q31',
             
          },
          {
            fieldName: "Wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "cornhybrid_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q32',
            isPercentage: true
            
          }
        ],
      },
      {
        subhead: "Paddy Hybrids",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: false,
            type: "text",
            identifier: "SEE_paddyybrid_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
             qID:'s2q33',
             isPercentage: true
          },
          {
            fieldName: "Total Sales(In Lakhs)",
            isOptional: false,
            type: "text",
            identifier: "SEE_paddyybrid_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q34'
          },
          {
            fieldName: "Wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "paddyybrid_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q35',
             isPercentage: true
            
          }
        ],
      },
      {
        subhead: "Vegetable Hybrids",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: false,
            type: "text",
            identifier: "SEE_vegetablehybrid_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
             qID:'s2q36',
             isPercentage: true
          },
          {
            fieldName: "Total Sales(In Lakhs)",
            isOptional: false,
            type: "text",
            identifier: "SEE_vegetablehybrid_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q37'
          },
          {
            fieldName: "Wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "vegetablehybrid_wholesalepercentage",
            placeholder: "Input here",
             qID:'s2q38',
             isPercentage: true
            
          }
        ],
      },
      {
        subhead: "Other Seeds(OPV & Other hybrids)",
        questions: [
          {
            fieldName: "Total Sales(in %)",
            isOptional: false,
            type: "text",
            identifier: "SEE_otherhybrid_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
             qID:'s2q39',
             isPercentage: true
          },
          {
            fieldName: "Total Sales(In Lakhs)",
            isOptional: false,
            type: "text",
            identifier: "SEE_otherhybrid_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q40'
          },
          {
            fieldName: "Wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "otherhybrid_wholesalepercentage",
            placeholder: "Input here",
            qID:'s2q41',
            isPercentage: true
            
          }
        ],
      },
    ],
    havingtaileend: {
      questions: [
        {
          fieldName:
            "Total wholesale in 2024 (in%)",
          isOptional: false,
          type: "text",
          identifier: "totalwholesalesseedin2024",
          placeholder: "Input here",
          autopopulateColumn: true,
            qID:'s2q42'
        },
      ],
    },
  },

  // {
  //   sectionHead: "Wat is the total number of retailers you serve",
  //   questionID: 3,
  //   questions: [
  //     {
  //       fieldName: "Wat is the total number of retailers you serve",
  //       isOptional: false,
  //       type: "text",
  //       identifier: "noOfRetailers",
  //       placeholder: "Input here",
  //     },
  //   ],
  // },
  {
    sectionHead:
      "What is the number of retailers you serve",
    questionID: 4,
    questions: [
      {
        fieldName:
          "",
        isOptional: false,
        type: "text",
        identifier: "noofretailersserve",
        placeholder: "Input here",
            qID:'s2q43'
      },
    ],
  },
  {
    sectionHead:
      "What percentage of your wholesale business is in cash(in %)",
    questionID: 5,
    questions: [
      {
        fieldName:
          "",
        isOptional: false,
        type: "text",
        identifier: "wholesalebusinusincash",
        placeholder: "Input here",
            qID:'s2q44',
            isPercentage: true
      },
    ],
  },
  {
    sectionHead:
      "What percentage of your retail business is in cash(in %)",
    questionID: 6,
    questions: [
      {
        fieldName:
          "",
        isOptional: false,
        type: "text",
        identifier: "retailebusinusincash",
        placeholder: "Input here",
            qID:'s2q45',
            isPercentage: true
        
      },
    ],
  },

  {
    sectionHead:
      "Please provide percentage share of your total crop protection product purchased from different sources in the year 2024",
    questionID: 7,
    sectionquestions: [
      {
        subhead: "from distributor",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: false,
            type: "text",
            identifier: "fromdistributor_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            qID:'s2q46',
            isPercentage: true
          },
          {
            fieldName: "Total Sales INR",
            isOptional: false,
            type: "text",
            identifier: "fromdistributor_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
            qID:'s2q47'
          }
        ],
      },
      {
        subhead: "Direct from company",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: false,
            type: "text",
            identifier: "directfromcompany_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            qID:'s2q48',
            isPercentage: true
          },
          {
            fieldName: "Total Sales INR",
            isOptional: false,
            type: "text",
            identifier: "directfromcompany_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
            qID:'s2q49'

          }
        ],
      },
      {
        subhead: "Online stores(ABM, ecom)",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: false,
            type: "text",
            identifier: "online_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            qID:'s2q50',
            isPercentage: true
          },
          {
            fieldName: "Total Sales INR",
            isOptional: false,
            type: "text",
            identifier: "online_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
            qID:'s2q51'
          }
        ],
      },
      {
        subhead: "Govt Bodies",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: false,
            type: "text",
            identifier: "govtbodies_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            isPercentage: true
          },
          {
            fieldName: "Total Sales INR",
            isOptional: false,
            type: "text",
            identifier: "govtbodies_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
            qID:'s2q52'
          }
        ],
      },
      {
        subhead: "Other Wholesalers",
        questions: [
          {
            fieldName: "Total Purchase(in %)",
            isOptional: false,
            type: "text",
            identifier: "others_totalsale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            qID:'s2q53',
            isPercentage: true
          },
          {
            fieldName: "Total Sales INR",
            isOptional: false,
            type: "text",
            identifier: "others_retail",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
            qID:'s2q54'
          }
        ],
      },
    ],
  },


  {
    sectionHead: "Top 5 companies CP businesses in 2024 of your counter",
    questionID: 8,
    sectionquestions: [
      {
        subhead: "Company 1",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_nametop1",
            placeholder: "Input here",
            options:companyNames.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
            qID:'s2q55'
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_type1",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: true,
            isFirst: false,
            qID:'s2q56'
          },
          {
            fieldName: "total business 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "company_top_nametop1total",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            isHalf: true,
            isFirst: true,
            qID:'s2q57'
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "company_top_namtotalsale1",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            qID:'s2q58'
          },
        ],
      },
      {
        subhead: "Company 2",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_nametop2",
            placeholder: "Input here",
            options:companyNames.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
            qID:'s2q59'
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_type2",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: true,
            isFirst: false,
            qID:'s2q60'
          },
          {
            fieldName: "total business 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "company_top_nametop2total",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            isHalf: true,
            isFirst: true,
            qID:'s2q61'
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "company_top_namtotalsale2",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            qID:'s2q62'
          },
        ],
      },
      {
        subhead: "Company 3",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_nametop3",
            placeholder: "Input here",
            options:companyNames.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
            qID:'s2q63'
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_type3",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: true,
            isFirst: false,
            qID:'s2q64'
          },
          {
            fieldName: "total business 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "company_top_nametop3total",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            isHalf: true,
            isFirst: true,
            qID:'s2q65'
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "company_top_namtotalsale3",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            qID:'s2q66'
          },
        ],
      },
      {
        subhead: "Company 4",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_nametop4",
            placeholder: "Input here",
            options:companyNames.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
            qID:'s2q67'
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_type4",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: true,
            isFirst: false,
            qID:'s2q68'
          },
          {
            fieldName: "total business 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "company_top_nametop4total",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            isHalf: true,
            isFirst: true,
            qID:'s2q69'
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "company_top_namtotalsale4",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            qID:'s2q70'
          },
        ],
      },
      {
        subhead: "Company 5",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_nametop5",
            placeholder: "Input here",
            options:companyNames.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
            qID:'s2q71'
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "company_top_type5",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: true,
            isFirst: false,
            qID:'s2q72'
          },
          {
            fieldName: "total business 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "company_top_nametop5total",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            isHalf: true,
            isFirst: true,
            qID:'s2q73'
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "company_top_namtotalsale5",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            qID:'s2q74'
          },
        ],
      },
      {
        subhead: "Bayer",
        questions: [
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "bayeruserType",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: false,
            isFirst: false,
            qID:'s2q75'
          },
          {
            fieldName: "total Sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "bayertotal2024",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            isHalf: true,
            isFirst: true,
            qID:'s2q76'
          },
          {
            fieldName: "wholesale(in %)",
            isOptional: false,
            type: "text",
            identifier: "bayerwholesale",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            qID:'s2q77'
          },
        ],
      },

      {
        subhead: "Other Companies",
        questions: [
          {
            fieldName: "Total sales (In lakhs)",
            isOptional: false,
            type: "text",
            identifier: "othercompaniesaggregatedtotal",
            placeholder: "Input here",
            // isHalf: true,
            // isFirst: true,
            autopopulateColumn: true,
            qID:'s2q78'
          },
          
          
        ],
      },
      {
        subhead: "Total sales",
        questions: [
          {
            fieldName: "Total sales (In lakhs)",
            isOptional: false,
            type: "text",
            identifier: "totalcompaniesaggregated",
            placeholder: "Input here",
            // isHalf: true,
            // isFirst: true,
            autopopulateColumn: true,
            qID:'s2q79'
          }
          
        ],
      },

    ],
  },





  {
    sectionHead: "Top 3 companies RICE(Hybrid seed businesses in 2024 of your counter",
    questionID: 9,
    sectionquestions: [
      {
        subhead: "Company 1",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "companynamericetop1_RICE1",
            placeholder: "Input here",
            options:companyNames.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
            qID:'s2q80'
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "ricecompanytype1_RICE1",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: true,
            isFirst: false,
            qID:'s2q81'
          },
          {
            fieldName: "Total sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "retailsalesrice_RICE1_totalsale",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            qID:'s2q82',
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume in Mt",
            isOptional: false,
            type: "textWithUnit",
            identifier: "retailvolumerice_RICE1",
            placeholder: "Input here",
            options:[{id: 1, value: 'MT'}, {id: 2, value: 'KG'}],
            qID:'s2q83',
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: false,
            type:"text",
            identifier: "wholesaleinpercentageRice_RICE1",
            placeholder: "Input here",
            qID:'s2q84',
            isHalf: true,
            isFirst: true,
            isPercentage: true
          },
          {
            fieldName: "Average NDP(rs)",
            isOptional: false,
            type: "text",
            identifier: "averagendp1",
            placeholder: "Input here",
            qID:'s2q85',
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Company 2",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "companynamericetop2",
            placeholder: "Input here",
            options:companyNames.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
            qID:'s2q86'
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "ricecompanytype2_RICE2",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: true,
            isFirst: false,
            qID:'s2q87'
          },
          {
            fieldName: "Total sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "retailsalesrice_RICE2_totalsale",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            qID:'s2q88',
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume in Mt",
            isOptional: false,
            type: "textWithUnit",
            identifier: "retailvolumerice_RICE2",
            placeholder: "Input here",
            options:[{id: 1, value: 'MT'}, {id: 2, value: 'KG'}],
            qID:'s2q89',
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: false,
            type:"text",
            identifier: "wholesaleinpercentageRice_RICE2",
            placeholder: "Input here",
            qID:'s2q90',
            isHalf: true,
            isFirst: true,
            isPercentage: true
          },
          {
            fieldName: "Average NDP(rs)",
            isOptional: false,
            type: "text",
            identifier: "averagendp2",
            placeholder: "Input here",
            qID:'s2q91',
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Company 3",
        questions: [
          {
            fieldName: "company",
            isOptional: false,
            type: "singleSelect",
            identifier: "companynamericetop3",
            placeholder: "Input here",
            options:companyNames.sort((a, b) => {
              return a.value.localeCompare(b.value);
            }),
            isHalf: true,
            isFirst: true,
            qID:'s2q92'
          },
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "ricecompanytype3_RICE3",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: true,
            isFirst: false,
            qID:'s2q87'
          },
          {
            fieldName: "Total sales 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "retailsalesrice_RICE3_totalsale",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            qID:'s2q93',
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Total volume in Mt",
            isOptional: false,
            type: "textWithUnit",
            identifier: "retailvolumerice_RICE3",
            placeholder: "Input here",
            options:[{id: 1, value: 'MT'}, {id: 2, value: 'KG'}],
            qID:'s2q89',
            isHalf: true,
            isFirst: false,
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: false,
            type:"text",
            identifier: "wholesaleinpercentageRice_RICE3",
            placeholder: "Input here",
            qID:'s2q90',
            isHalf: true,
            isFirst: true,
            isPercentage: true
          },
          {
            fieldName: "Average NDP(rs)",
            isOptional: false,
            type: "text",
            identifier: "averagendp3",
            placeholder: "Input here",
            qID:'s2q96',
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },
      {
        subhead: "Bayer",
        questions: [
          {
            fieldName: "Retailer/Distributor",
            isOptional: false,
            type: "singleSelect",
            identifier: "bayeruserTyperice_BayerRICE",
            placeholder: "Input here",
            options:[{id: 1, value: 'Retailer'}, {id: 2, value: 'Distributor'}],
            isHalf: false,
            isFirst: false,
            qID:'s2q97'
          },
          {
            fieldName: "Total sale in 2024",
            isOptional: false,
            type: "textWithUnit",
            identifier: "bayerricetotal2024_BayerRICE_totalsale",
            placeholder: "Input here",
            options:[{id: 1, value: 'Lakhs'}, {id: 2, value: 'Crores'}],
            isHalf: true,
            isFirst: true,
            qID:'s2q98'
          },
          {
            fieldName: "Total Volume in MT",
            isOptional: false,
            type: "textWithUnit",
            options:[{id: 1, value: 'MT'}, {id: 2, value: 'KG'}],
            identifier: "bayerricewretailvolume_BayerRICE",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            qID:'s2q99'
          },
          {
            fieldName: "Wholesale (in%)",
            isOptional: false,
            type:"text",
            identifier: "wholesaleinpercentageRice_BayerRICE",
            placeholder: "Input here",
            qID:'s2q100',
            isHalf: true,
            isFirst: true,
          },
          {
            fieldName: "Average NDP(rs)",
            isOptional: false,
            type: "text",
            identifier: "averagendp_BayerRICE",
            placeholder: "Input here",
            qID:'s2q101',
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
          },
        ],
      },

      {
        subhead: "Other Companies",
        questions: [
          {
            fieldName: "Total Sales (in Lakhs)",
            isOptional: false,
            type: "text",
            identifier: "otherRetailRice",
            placeholder: "Input here",
            
            autopopulateColumn: true,
            qID:'s2q102'
          },
          
        ],
      },

      {
        subhead: "Total Retail sales and volume",
        questions: [
          {
            fieldName: "Retail sales (In lakhs)",
            isOptional: false,
            type: "text",
            identifier: "totalRetailRice",
            placeholder: "Input here",
            isHalf: true,
            isFirst: true,
            autopopulateColumn: true,
             qID:'s2q103'
          },
          {
            fieldName: "Retail Volume in MT",
            isOptional: false,
            type: "text",
            identifier: "totalRetailRiceVolume",
            placeholder: "Input here",
            isHalf: true,
            isFirst: false,
            autopopulateColumn: true,
             qID:'s2q103'
          }
          
        ],
      },
      

    ],
  },

];
