import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [territoryId, setTerritoryId] = useState(null);
  const [userId, setUserId] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setTerritoryId(searchParams.get('territory_id'));
    setUserId(searchParams.get('user_id'));
  }, [searchParams]);

  const value = {
    territoryId,
    userId,
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);