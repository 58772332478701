import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Simulating an API call
export const fetchSubDistricts = createAsyncThunk(
  "subDistricts",
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI; 
    dispatch(setLoaderTrue());
  }
);

const subDistSlice = createSlice({
  name: "subDistricts",
  initialState: {
    selectedFOT: null,
    loading: false,
    error: null,
    selectedSubDist: null,
    selectedFOTvalue:null
  },
  reducers: {
    setLoaderTrue: (state, action) => {
        state.loading = true;
    },
    setSelectedSubDistrict: (state, action) => {
      state.selectedSubDist = action.payload;
    },
    setSelectedFOT: (state, action) => {
      state.selectedFOT = action.payload;
    },
    setFOTValue:(state, action) => {
      state.selectedFOTvalue = action.payload;
    },
  
    resetFOTandSubDistrict:(state, action) => {
      state.selectedFOT = null;
      state.selectedSubDist = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubDistricts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubDistricts.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchSubDistricts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export const { setLoaderTrue, setSelectedSubDistrict, setSelectedFOT, resetFOTandSubDistrict, setFOTValue  } = subDistSlice.actions;
export default subDistSlice.reducer;