import { colors, Font, Gap } from "./standards";

export const Style = {
    container: {
            background: colors.Primary,
            padding: Gap.standardGap,
            display: 'flex',
            justifyContent:'space-between',
            alignItems:'center',
            zIndex:99999999
    },
    heading:{
        ...Font.RobotoBoldItalic,
        fontSize: 20,
        color: colors.Blue_Dark,
        display:'block'
    },
    subheading:{
        ...Font.RobotoBold,
        fontSize: 18,
        color: colors.Blue_Dark,
        display:'block'
    },
    typeonecontainer:{
        background: colors.Primary,
        padding: Gap.standardGap,
        display: 'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    seperator:{
        width:50,
        height:3,
        background:colors.Blue_Heilight,
        display:'block',
        marginTop:15,
        marginBottom:15
    },
    back:{
        fontSize:22,
        color: colors.Blue_Dark,
        marginRight:20,
    }
}