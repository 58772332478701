import React, { useState, useRef, useEffect } from 'react';
import { animated, useSpring, config } from 'react-spring';

import { colors, Font } from "../styles/standards";

export const Toast = ({ message }) => {
  const [isVisible, setIsVisible] = useState(false);
  const toastRef = useRef(null);
  const animation = useSpring({
    from: { opacity: 0, transform: 'translateX(100%)' },
    to: { opacity: 1, transform: 'translateX(0%)' },
    config: config.gentle,
  });

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(() => setIsVisible(false), 3000); // Hide after 3 seconds
      return () => clearTimeout(timeout);
    }
  }, [isVisible]);

  const handleSwipeStart = (event) => {
    const startX = event.clientX;
    const toastWidth = toastRef.current.offsetWidth;

    const handleSwipeMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      if (deltaX > 0) { // Swipe right
        animation.set({ transform: `translateX(${deltaX}px)` });
      }
    };

    const handleSwipeEnd = () => {
      document.removeEventListener('mousemove', handleSwipeMove);
      document.removeEventListener('mouseup', handleSwipeEnd);

      if (animation.x.get() > toastWidth / 2) { // Hide if swiped past half
        setIsVisible(false);
      } else {
        animation.set({ transform: 'translateX(0%)' });
      }
    };

    document.addEventListener('mousemove', handleSwipeMove);
    document.addEventListener('mouseup', handleSwipeEnd);
  };

  return (
    <animated.div
      ref={toastRef}
      style={{
        ...animation,
        left: '5%',
        position: "fixed",
        width: '85%',
        top: 50,
        zIndex: 2147483647,
        background: '#abd4ae',
        padding: 13,
        color: colors.Black,
        textAlign: 'left',
        borderRadius: 8,
        ...Font.RobotoMedium,
        border: '1px solid #6fe66f',
        touchAction: 'none', // Disable default touch actions
      }}
      onMouseDown={handleSwipeStart}
    >
      {message}
    </animated.div>
  );
};