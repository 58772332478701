import { Loading } from "../constants"
import { colors, Font } from "../styles/standards"

export const LoaderFaded = () => {
    return(
        <div style={Styles.container}>
            <img src={Loading} style={{height:30}} alt="loader" />
        </div>
    )
}

export const Styles = {
    container:{
        position:'fixed',
        left:0,
        right:0,
        top:0,
        bottom:0,
        background:'rgba(255, 255, 255,0.8)',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        zIndex:9999,

    },
    loadertext:{
        ...Font.RobotoBoldItalic
    }
}