export const colors = {
  Primary: "#e7eef9",
  Black: "#000",
  White: "#fff",
  Blue: "#5cacd4",
  Blue_Dark: "#264360",
  Blue_Heilight: '#2b95ff',
  Grey: '#efefef',
  Grey_Dark:'#767676'
};
export const Font = {
  Robotoregular: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 100,
    fontStyle: "normal",
  },
  RobotoMedium: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
  },
  RobotoBold: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
  },
  RobotoBoldItalic: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    fontStyle: "italic",
  },
};

export const Gap = {
  standardGap: 20,
};
