import { getDistrict, getStates, getSubDistrict, getVillages } from "../helper-functions";



export const config1 = [
    {
        fieldName: 'Contact Person Name',
        isOptional: false,
        type: 'text',
        identifier: 'name',
        placeholder: 'Name',
        qID: 'q1'
    },
    {
        fieldName: 'Mobile Number',
        isOptional: false,
        type: 'text',
        identifier: 'mobile_number',
        placeholder: 'Mobile Number',
        qID: 'q2'
    },
    {
        fieldName: 'Email Address',
        isOptional: false,
        type: 'text',
        identifier: 'email',
        placeholder: 'Email',
        qID: 'q3',
    },
    {
        fieldName: 'Age',
        isOptional: false,
        type: 'text',
        identifier: 'dob',
        placeholder: 'Enter your Age',
        qID: 'q4'
    },
    {
        fieldName: 'Name of firm',
        isOptional: false,
        type: 'text',
        identifier: 'shop_name',
        placeholder: 'Name of firm',
        qID: 'q5'
    },
    {
        fieldName: 'Type of establishment',
        isOptional: false,
        type: 'singleSelect',
        identifier: 'establishmentType',
        placeholder: 'Type of establishment',
        options:[{id: 1, value: 'type 1'},{id: 2, value: 'type 2'},{id: 3, value: 'type 3'}],
        qID: 'q6'
    },
    {
        fieldName: 'Do you sell bayer products?',
        isOptional: false,
        type: 'radio',
        identifier: 'businesspartner',
        placeholder: 'Select',
        options:[{id: 1, value: 'yes'}, {id: 2, value: 'no'}],
        qID: 'q8'
    },
    {
        fieldName: 'Customer Type',
        isOptional: false,
        type: 'singleSelect',
        identifier: 'customerType',
        placeholder: 'Customet Type',
        options:[{id: 1, value: 'Distributor'},{id: 2, value: 'Retailer'},{id: 3, value: 'FPO'},{id: 4, value: 'BLF'},{id: 5, value: 'Cooperative Socities/Govt Bodies'},{id: 6, value: 'e-retailer'}],
        qID: 'q7'
    },
    
    {
        fieldName: 'State',
        isOptional: false,
        type: 'singleSelect',
        identifier: 'state',
        placeholder: 'Select',
        options: await getStates(),
        qID: 'q9'
    },
    {
        fieldName: 'District',
        isOptional: false,
        type: 'singleSelect',
        identifier: 'district',
        placeholder: 'Select',
        options: [],
        isHalf: true,
        isFirst: true,
        qID: 'q10'
    },
    {
        fieldName: 'Sub District',
        isOptional: false,
        type: 'singleSelect',
        identifier: 'subdistrict',
        placeholder: 'Select',
        options:[],
        isHalf: true,
        isFirst: false,
        qID: 'q11'
    },
    {
        fieldName: 'Choose between Village or Urban Body',
        isOptional: false,
        type: 'radio',
        identifier: 'radioSelection',
        placeholder: 'Select',
        options:[
            { id: "village", value: "Village" },
            { id: "urban", value: "Urban Body" },
          ],
        qID: 'q12'
    },
    {
        fieldName: 'Village',
        isOptional: true,
        type: 'singleSelect',
        identifier: 'village',
        placeholder: 'Select',
        // options: await getVillages(),
        options: [],
        isHalf: true,
        isFirst: true,
        qID: 'q13'
    },
    {
        fieldName: 'Urban Body',
        isOptional: true,
        type: 'singleSelect',
        identifier: 'urban',
        placeholder: 'Select',
        options:[],
        isHalf: true,
        isFirst: false,
        qID: 'q14'
    },
    {
        fieldName: 'Territory',
        isOptional: false,
        type: 'singleSelect',
        identifier: 'territory',
        placeholder: 'Select',
        options:[],
        isHalf: true,
        isFirst: true,
        qID: 'q15'

    },
    {
        fieldName: 'FOT',
        isOptional: false,
        type: 'singleSelect',
        identifier: 'fot',
        placeholder: 'Select',
        options:[],
        isHalf: true,
        isFirst: false,
        qID: 'q16'
    },
    {
        fieldName: 'PIN Code',
        isOptional: false,
        type: 'text',
        identifier: 'pin',
        placeholder: 'Enter 6-Digiot PIN code',
        qID: 'q17'
    },
    {
        fieldName: 'GSTIN NO',
        isOptional: true,
        type: 'text',
        identifier: 'gst',
        placeholder: 'Enter GSTIN Number',
        qID: 'q18'
    },
    {
        fieldName: 'No Of Years in Pesticides/Seed Business',
        isOptional: false,
        type: 'singleSelect',
        identifier: 'noOfYearsInServiece',
        placeholder: 'Select',
        options:[{id: 1, value: '0-3 years'}],
        qID: 'q19'
    },
    {
        fieldName: 'Are you a farmer',
        isOptional: false,
        type: 'radio',
        identifier: 'farmer',
        placeholder: 'Select',
        options:[{id: 1, value: 'yes'}, {id: 2, value: 'no'}],
        qID: 'q20'
    },
    {
        fieldName: 'Are you a Agri Graduate',
        isOptional: false,
        type: 'radio',
        identifier: 'agrigraduate',
        placeholder: 'Select',
        options:[{id: 1, value: 'yes'}, {id: 2, value: 'no'}],
        qID: 'q21'
    },
    {
        fieldName: 'Google Cordinate Of Shop',
        isOptional: true,
        type: 'cordinates',
        identifier: 'cordinates',
        placeholder: 'Interaction Pending',
        qID: 'q22'
    },
    {
        fieldName: 'Photo Of Shop',
        isOptional: false,
        type: 'file',
        identifier: 'shopPhoto',
        placeholder: 'Click to Take a photo',
        qID: 'q23'
    },
    
    {
        fieldName: 'Do you have a license to sell Pesticides?',
        isOptional: false,
        type: 'radio',
        identifier: 'liscencePesticide',
        placeholder: 'Select',
        options:[{id: 1, value: 'yes'}, {id: 2, value: 'no'}],
        qID: 'q24'
    },
    {
        fieldName: 'Do you have license to sell Seeds?',
        isOptional: false,
        type: 'radio',
        identifier: 'liscenceSeeds',
        placeholder: 'Select',
        options:[{id: 1, value: 'yes'}, {id: 2, value: 'no'}],
        qID: 'q25'
    },
    {
        fieldName: 'Do you have license to sell Fertilizers?',
        isOptional: false,
        type: 'radio',
        identifier: 'liscenceFertilizer',
        placeholder: 'Select',
        options:[{id: 1, value: 'yes'}, {id: 2, value: 'no'}],
        qID: 'q26'
    },

]