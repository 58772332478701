import { useState } from "react";
import { Header } from "../components/headerTypeOne";
import { Constants } from "../constants/textconstants";
import { Style } from "../styles/step1";
import { SaveAndCont } from "../components/saveandcont";
import { colors, Font } from "../styles/standards";
import { SurveyCompleted } from "../components/surveyCompleted";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../config/api";

const configuration = [
  {
    sectionHead:
      "Based on your experiance, give rank to the activities below in terms of their effectiveness in influencing purchace dicision of farmers.",
    questionID: 1,
    activeRank:0,
    data: [
      {
        id: 1,
        rank: "",
        label: "Demonstration Trials",
      },
      {
        id: 2,
        rank: "",
        label: "Farmers Meeting, Training and Advisory",
      },
      {
        id: 3,
        rank: "",
        label: "Advertising Mass Media(TV, Radio, news papper listed)",
      },
      {
        id: 4,
        rank: "",
        label: "Onground campaigns: Display leaflets, branding",
      },
      {
        id: 5,
        rank: "",
        label: "Retailer servbiece recomendation",
      },
      {
        id: 6,
        rank: "",
        label: "Govt bodies recomendation",
      },
      {
        id: 7,
        rank: "",
        label: "Fellow farmers recomendation",
      },
      {
        id: 8,
        rank: "",
        label: "Credit Facility at POS",
      },
    ],
    other: "",
  },
  {
    sectionHead:
      "Based on your experiance, give rank to the activities below in terms of their effectiveness in influencing purchace dicision of Retailers.",
    questionID: 2,
    activeRank:0,
    data: [
      {
        id: 1,
        rank: "",
        label: "Credit Facility",
      },
      {
        id: 2,
        rank: "",
        label: "Company demand generation",
      },
      {
        id: 3,
        rank: "",
        label: "Farmer demand",
      },
      {
        id: 4,
        rank: "",
        label: "Brand Image and performance",
      },
      {
        id: 5,
        rank: "",
        label: "Profit Margin",
      },
      {
        id: 6,
        rank: "",
        label: "Branding and advertisement",
      },
      {
        id: 7,
        rank: "",
        label: "Timely Placement",
      },
      {
        id: 8,
        rank: "",
        label: "Distributor Influence",
      },
      {
        id: 9,
        rank: "",
        label: "Advertising,( TV Radio newspapper listed)",
      },
      {
        id: 10,
        rank: "",
        label: "Loyalty/Liquidation Program",
      },
      {
        id: 9,
        rank: "",
        label: "Distributor run schemes",
      },
      {
        id: 9,
        rank: "",
        label: "Gifts/Rewards",
      }
    ],
    other: "",
  },
];

export const Step3 = () => {
  const [formData, setFormData] = useState({});
  const [config, setConfig] = useState(configuration);
  const [showSuccess, setShowSucess] = useState(false);
  const [preference, setPreference] = useState({preference1:'',
    preference2:''})

    const data = useLocation();

    const navigate = useNavigate();

    const validateRanks = () => {
      return config.every((section) => section.data.every((item) => item.rank !== ""));
    };

    const submitResponse = () => {
      if (!validateRanks()) {
        console.error("Please rank all activities in both sections");
        window.alert("Please rank all fields in both sections")
        return; // Prevent submission if validation fails
      }
      let PostBody = config;
      const structured = 
      {
       user_id: data.state.user_id,
       page_number: "page3",
       responses: PostBody,
       action: "save",
       customer_id: data.state.customerID

     }


       post('/survey/save', PostBody)
      .then((response) => {
        setShowSucess(true);
        setTimeout(()=>{
          setShowSucess(false);
          navigate(`/?territory_id=${data.state.territory_id}&fo_id=${data.state.user_id}`);
        }, 3000)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }


  const handleChange = (identifier, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [identifier]: value,
    }));
  };
  const setothers = (data,questionID ) => {
    let newConfig = config;
    newConfig.forEach((section) => {
        if (section.questionID === questionID) {
          section["other"] = data
        }
      });

     setConfig(newConfig)
  }
  const toggleRank = (item, questionID) => {
    setConfig((prevConfig) => {
      const newConfig = prevConfig.slice(); // Create a copy to avoid mutation
      newConfig.forEach((section) => {
        if (section.questionID === questionID) {
          section.data.forEach((data) => {
            if (data.id === item.id && item.rank === "") {
              let newRank = section.activeRank + 1;
              section.activeRank = newRank;
              data.rank = newRank;
            }
          });
        }
      });
      return newConfig;
    });
  };
  const resetSelection = (id) => {
    setConfig((prevConfig) => {
      const newConfig = prevConfig.slice();
      newConfig.forEach((item) => {
        if (item.questionID === id) {
          item.activeRank = 0;
          item.data.map((inner) => {
            inner.rank = "";
          });
        }
      });
      return newConfig;
    }, () => {
      // call back 
    });
  };
 
  return (
    <div>
      {showSuccess && <SurveyCompleted/>}
      <Header header={Constants.retailer_survey} subheader={Constants.step3} />
      <div style={Style.container}>
        {config.map((section) => {
          return (
            <div>
              <h4>
                {section.questionID}. {section.sectionHead}
              </h4>

              <span
                style={{
                  display: "block",
                  marginBottom: 20,
                  ...Font.RobotoMedium,
                  fontSize: 12,
                }}
              >
                Select the activities to allot the rank, most effective activity
                should be selected first.
              </span>
              <span
                style={{
                  display: "block",
                  color: colors.Blue_Heilight,
                  marginBottom: 20,
                }}
                onClick={() => {
                  resetSelection(section.questionID);
                }}
              >
                Reset selections
              </span>
              {section.data.map((item, i) => {
                return (
                  <div
                  key={i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 10,
                    }}
                    onClick={() => {
                      toggleRank(item, section.questionID);
                    }}
                  >
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        border: "1px solid #767676",
                        borderRadius: 5,
                        background:
                          item.rank !== ""
                            ? colors.Blue_Heilight
                            : colors.White,
                        color: item.rank !== "" ? colors.White : colors.White,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.rank}
                    </div>
                    <span
                      style={{
                        display: "block",
                        ...Font.RobotoMedium,
                        marginTop: 10,
                        marginLeft: 20,
                      }}
                    >
                      {item.label}
                    </span>
                  </div>
                );
              })}
              <div>
                <span style={{...Font.RobotoMedium}}>Others Please specify</span>
                <input
                  type="text"
                  onChange={(e)=>{
                    setothers(e.target.value, section.questionID)
                  }}
                  style={{
                    boxSizing: "border-box",
                    display: "block",
                    width: "100%",
                    padding: 15,
                    marginTop: 6,
                    border: `1px solid ${colors.Grey_Dark}`,
                    borderRadius: 4,
                  }}
                />
              </div>
            </div>
          );
        })}

              <h4>
                3. What can be the most effective activities which can help tofurther increase your sale of Bayer products, Suggest two activities in order of your preference
              </h4>
              <div>
                <span style={{...Font.RobotoMedium}}>Preference 1</span>
                <input
                  type="text"
                  onChange={(e)=>{
                    setPreference({...preference, preference1: e.target.value})
                  }}
                  style={{
                    boxSizing: "border-box",
                    display: "block",
                    width: "100%",
                    padding: 15,
                    marginTop: 6,
                    border: `1px solid ${colors.Grey_Dark}`,
                    borderRadius: 4,
                  }}
                />
              </div>
              <div>
                <span style={{...Font.RobotoMedium}}>Preference 2</span>
                <input
                  type="text"
                  onChange={(e)=>{
                    setPreference({...preference, preference2: e.target.value})
                  }}
                  style={{
                    boxSizing: "border-box",
                    display: "block",
                    width: "100%",
                    padding: 15,
                    marginTop: 6,
                    border: `1px solid ${colors.Grey_Dark}`,
                    borderRadius: 4,
                  }}
                />
              </div>
      </div>
      <SaveAndCont onContinue={() => { submitResponse()}} />
    </div>
  );
};
