import { colors, Font } from "./standards";

export const Styles = {
    container:{
        background: colors.White,
        border:`2px solid ${colors.Blue_Heilight}`,
        padding:'18px 25px',
        borderRadius: 6,
        display:'flex',
        justifyContent: 'space-between'
    },
    surveyText:{
        ...Font.RobotoMedium,
        color:colors.Blue_Heilight,
        marginLeft:12,
        fontSize: 15
    },
    plusIcon:{
        color:colors.Blue_Heilight,
        fontWeight:200,
        marginTop:2
    }
}