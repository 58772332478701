import { configureStore } from '@reduxjs/toolkit';
import formConfig from './slices/formconfig';
import subDist from './slices/subDistricts';
import retailers from './slices/retailersList'
import Utils from './slices/util'

const store = configureStore({
  reducer: {
    formConfig: formConfig,
    subDist,
    retailers,
    Utils
  },
});

export default store;