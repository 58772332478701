
import { Loading } from "../constants";
import { colors, Font, Gap } from "../styles/standards";



export const ActionButton = ({ onPress, text, loader, isEnabled }) => {
  return (
    <div
      onClick={onPress}
      style={{
        ...styles.buttonContainer,
        background: isEnabled? colors.Blue_Heilight: colors.Grey
      }}
    >
      {loader ? (
        <img src={Loading} style={styles.loader} alt="loader" />
      ) : (
        <span style={{...styles.actionButtonText,
            color: isEnabled? colors.White: colors.Grey_Dark
        }}>{text}</span>
      )}
    </div>
  );
};

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: Gap.standardGap,
    borderRadius: 4,
    marginTop: 20,
  },
  actionButtonText: {
    ...Font.RobotoBold,
  },
  loader: {
    height: 20,
  },
};
