import React, { useState } from "react";
import { colors, Font } from "../styles/standards";

const TextInputWithUnit = ({ placeholder, currentvalue, onChange ,options, hasError}) => {
  
  const [value, setValue] = useState("");
  const [unit, setUnit] = useState(options[0].value.toLowerCase());

  const handleValueChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    console.log(options)
    if (onChange) {
     
      if(unit==='lakhs'){
        console.log("CAME 1")
        onChange(newValue.replace(/\D/g, '') * 100000);
      }else if(unit==='crores'){
        console.log("CAME 2")
        onChange(newValue.replace(/\D/g, '') * 10000000);
      } else if(unit==='mt'){
        console.log("CAME INCORRECTLT", value.replace(/\D/g, '') )
        onChange(value.replace(/\D/g, '') * 1000);
      }else{
        console.log("CAME 3")
        onChange(value.replace(/\D/g, ''));
      }
    }
  };

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    setUnit(newUnit);
  
    if (onChange) {
      if(unit==='lakhs'){
        onChange(value.replace(/\D/g, '') * 100000);
      }else if(unit==='crores'){
        onChange(value.replace(/\D/g, '') * 10000000);
      }
      else if(unit==='mt'){
        onChange(value.replace(/\D/g, '') * 1000);
      }else{
        onChange(value.replace(/\D/g, ''));
      }
    }
  };
  
  return (
    <div style={{...styles.container,  border: hasError? '1px solid red':'1px solid #efefef'}}>
      <input
        type="text"
        value={value.replace(/\D/g, '') }
        onChange={handleValueChange}
        placeholder={placeholder}
        style={styles.input}
      />
      <select value={unit} onChange={handleUnitChange} style={styles.dropdown}>
        {options.map((item, i)=>
          <option key={i} value={item.value.toLowerCase()}>{item.value}</option>
        )}
        
      </select>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    width: "100%",
    overflow: "hidden",
   
  },
  input: {
    boxSizing: "border-box",
    display: "block",
    width: "100%",
    padding: 15,
    height: 50,
    border: `1px solid #a7a7a7`,
    borderRight: "0px solid #efefef",
    borderRadius: 4,
  },
  dropdown: {
    padding: 8,
    color:colors.Blue_Heilight,
    border: `1px solid #a7a7a7`,
    borderLeft: "0px solid #efefef",
    height: 50,
    ...Font.RobotoBold,
    fontSize: 14,
  },
};

export default TextInputWithUnit;
