import { colors, Font, Gap } from "./standards";

export const Styles = {
    overLay:{
        position: 'fixed',
        left:0,
        right:0,
        top:0,
        bottom:0,
        background: colors.White,
        zIndex:999,
        overflow:'scroll',
    },
    subDistBody:{
        
        paddingTop:170
    },
    row: {
        padding:Gap.standardGap,
        border:`1px solid #efefef`,
        ...Font.RobotoMedium,
        fontSize: 15
    },
    activeRow:{
        background:colors.Blue
    },
    distCode:{
        display:'block',
        ...Font.RobotoMedium,
        fontSize: 14
    },
    fixedHeader:{
        position:'fixed',
        left:0,
        right:0,
        top:0,
        background:colors.White,
        zIndex:9
    }
}