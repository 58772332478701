import { colors, Font, Gap } from "./standards";

export const Styles = {
    container: {
        display:'flex',
        flexDirection:'row',
        padding:Gap.standardGap
    },
    heading:{
        ...Font.RobotoMedium,
        color:colors.Blue_Dark,
        marginLeft:12,
        fontSize: 20
    },
    backIcon:{
        fontSize:20,
        color: colors.Blue_Dark
    },
    searchContainer:{
        padding:Gap.standardGap
    },
    fixedContainer:{
        position:'fixed',
        left:0,
        right:0,
        top:0,
        background:colors.White,
        zIndex:9
    }
   
}