export const questions = [
    {
      id: "100",
      name: "field 1",
      type: "multichoice",
      question: "sample question 1",
      options: [
        { answer: "answer1", isSelected: false, id: "1" },
        { answer: "answer2", isSelected: false, id: "2" },
        { answer: "answer3", isSelected: false, id: "3" },
      ],
    },
    {
      id: "101",
      name: "field 2",
      type: "multichoice",
      question: "sample question 2",
      options: [
        { answer: "answer1", isSelected: false, id: "1" },
        { answer: "answer2", isSelected: false, id: "2" },
        { answer: "answer3", isSelected: false, id: "3" },
      ],
    },
    {
      id: "102",
      name: "field 3",
      type: "singlechoice",
      question: "sample question 3",
      options: [
        { answer: "answer1", isSelected: false, id: "1" },
        { answer: "answer2", isSelected: false, id: "2" },
        { answer: "answer3", isSelected: false, id: "3" },
      ],
    },
    {
      id: "103",
      name: "field 4",
      type: "text",
      question: "sample question 3",
      value: "sample answer"
    },
  ];

  export const RetailersList = [
    {
      "customer_id": "a12345b6-c789-123d-4567-e890123fghij",
      "shop_name": "ABC Retailers",
      "retailer_type": "Retailer",
      "mobile_number": "9876543210",
      "sub_district_id": "SUB123",
      "sub_district": "Example Sub-District",
      "district_id": "DIST456",
      "district": "Example District",
      "state_id": "STATE789",
      "state": "Example State",
      "fot_id": "1",
      "fot": "test 1",
      "urban_body_id": null,
      "urban_body": null,
      "local_body_type": null,
      "territory_id": "TERR101",
      "territory": "Maruthi Nagar",
      "survey_status": "in_progress"
    },
    {
      "customer_id": "a12345b6-c789-123d-4567-e890123fghij",
      "shop_name": "DEF Retailers",
      "retailer_type": "Retailer",
      "mobile_number": "9876543211",
      "sub_district_id": "SUB1234",
      "sub_district": "Sub-District 1",
      "district_id": "DIST456",
      "district": "Example District",
      "state_id": "STATE789",
      "state": "Example State",
      "fot_id": "2",
      "fot": "test 2 ",
      "urban_body_id": null,
      "urban_body": null,
      "local_body_type": null,
      "territory_id": "TERR101",
      "territory": "Mathikere",
      "survey_status": "completed"
    },
    {
      "customer_id": "a12345b6-c789-123d-4567-e890123fghij",
      "shop_name": "GHI Retailers",
      "retailer_type": "Retailer",
      "mobile_number": "9876543212",
      "sub_district_id": "SUB11",
      "sub_district": "te Sub-District",
      "district_id": "DIST456",
      "district": "Example District",
      "state_id": "STATE789",
      "state": "Example State",
      "fot_id": "3",
      "fot": "test 3",
      "urban_body_id": null,
      "urban_body": null,
      "local_body_type": null,
      "territory_id": "TERR101",
      "territory": "Gangothri Circle",
      "survey_status": "open"
    }
  ];