import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { OTP } from './pages/otp';
import { VerifyOTP } from './pages/verifyOTP';
import { Step1 } from './pages/step1';
import { Step2 } from './pages/step2';
import { Step3 } from './pages/step3';
import { Toast } from './components/toast';
import { useSelector } from 'react-redux';
import { AppProvider } from './AppContext'; // Import AppProvider

function App() {
  const showToast = useSelector((state) => state.Utils);

  return (
    <AppProvider>
      <>
        {showToast.toast && <Toast message={showToast.toastmessage} />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/verify_otp" element={<VerifyOTP />} />
          <Route path="/step1" element={<Step1 />} />
          <Route path="/step2" element={<Step2 />} />
          <Route path="/step3" element={<Step3 />} />
        </Routes>
      </>
    </AppProvider>
  );
}

export default App;