import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { questions } from '../../localdata/dummydata';



export const fetchFormQuestionsData = createAsyncThunk(
  'data/fetchData',
  async (_, thunkAPI) => {
    // Simulating an API call
    const response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: questions });
      }, 1000);
    });
    return response.data;
  }
);

// Create a slice
const formConfig = createSlice({
  name: 'formConfig',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormQuestionsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFormQuestionsData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFormQuestionsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default formConfig.reducer;