import { Style } from "../styles/header"
import { NewSurvey } from "./newButton"

export const Header = ({isNewSurvay, header, subheader, userID, territoryID}) => {
    return(
        <div style={Style.container}>
            <span style={Style.heading}>{header}</span>
            <NewSurvey userID={userID} territoryID={territoryID}/>
        </div>
    )
}