import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { filterSubDistrictsandFOT } from "../../helper-functions";
import { API_URL } from "../../config/sys";
import { FetchRetailers, get } from "../../config/api";

// Simulating an API call
export const fetchAllRetailers = createAsyncThunk(
  "fetchAllRetailers",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setLoader(true));
    // INKVM

    get(`/retailers?territory_id=${data.territory_id}`).then(async (data) => {
      dispatch(setRetailers({ data: data.data }));
      const subDistrictsandFOT = await filterSubDistrictsandFOT(data.data);
      dispatch(setSubDistricts(subDistrictsandFOT.subdist));
      dispatch(setFOTList(subDistrictsandFOT.fot));
      dispatch(setLoader(false))
    });
  }
);

const RetailersSlice = createSlice({
  name: "fetchAllRetailers",
  initialState: {
    loading: false,
    error: null,
    retailers: [],
    fot: [],
    subdistrict: [],
  },
  reducers: {
    setRetailers: (state, action) => {
      state.retailers = action.payload.data;
      state.loading = false
    },
    setSubDistricts: (state, action) => {
      state.subdistrict = action.payload;
      
    },
    setFOTList: (state, action) => {
      state.fot = action.payload;
     
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRetailers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllRetailers.fulfilled, (state, action) => {
        // state.loading = true;
      })
      .addCase(fetchAllRetailers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setRetailers, setSubDistricts, setFOTList, setLoader } =
  RetailersSlice.actions;
export default RetailersSlice.reducer;
