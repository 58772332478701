import { colors, Font } from "../styles/standards"

export const ProceedNotification = ({close, proceed}) => {
    return(
        <div style={{position:'fixed', left:0, right:0, top:0, bottom:0, background:'rgba(0,0,0,0.5)', zIndex:99999999, display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div style={{background:'#fff', width:'90%'}}>
                <span style={{padding:20, display:'block', ...Font.RobotoBold, paddingBottom:0}}>Proceed To Next Step?</span>
                <span style={{display:'block', padding:20, ...Font.RobotoMedium}}>
                    Make sure you have reviewed all your responses before proceeding, Responses cant be edited in future.
                </span>
                <div style={{...Style.button, color: colors.White, background:colors.Blue_Heilight}} onClick={()=>{proceed()}}>Yes Proceed</div>
                <div style={{...Style.button, color: colors.Blue_Heilight}} onClick={()=>{close()}}>Cancel</div>
            </div>
        </div>
    )
}

const Style = {
    
    button:{
        width:'80%',
        display:'flex',
        marginLeft:'10%',
        alignItems:'center',
        justifyContent:'center',
        paddingTop:15,
        paddingBottom:15,
        border: `1px solid ${colors.Blue_Heilight}`,
        ...Font.RobotoBold,
        fontSize: 14,
        marginBottom:20
    }
}