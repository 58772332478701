import { get } from "../config/api";
import {
  districts,
  states,
  StatesAndDistricts,
  subDistricts,
  urbanBodies,
  villages,
} from "../config/statesanddistricts";

export const filterSubDistrictsandFOT = (inputArray) => {
  const result = { subdist: [], fot: [] };
  const subdistSet = new Set();
  // To track unique subdistrict entries
  const fotSet = new Set(); // To track unique FOT entries

  inputArray.forEach((item) => {
    if (item.sub_district && item.sub_district_id) {
      const subdistKey = `${item.sub_district}-${item.sub_district_id.trim()}`;
      if (!subdistSet.has(subdistKey)) {
        subdistSet.add(subdistKey);
        result.subdist.push({
          subdist: item.sub_district,
          subdistID: item.sub_district_id.trim(),
          relatedFT: item.fot_id,
        });
      }
    }
    if (item.fot_id && item.fot) {
      const fotKey = `${item.fot_id}-${item.fot}`;
      if (!fotSet.has(fotKey)) {
        fotSet.add(fotKey);
        result.fot.push({ fot: item.fot, fotid: item.fot_id });
      }
    }
  });

  return result;
};

export const getStates = async () => {
  let StatesData = [];
  await get(`/locations/states`).then(async (data) => {
    StatesData = data.data
  });
  const newData = StatesData.map((item, i) => ({
    id: item["state_code"],
    value: item["state_name"],
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getDistrict = async (state) => {
  // make API call here
  let DistrictsData = [];
  await get(`/locations/districts?state_code=${state}`).then(async (data) => {
    DistrictsData = data.data
  });
  const newData = DistrictsData.map((item, i) => ({
    id: item["district_code"],
    value: item["district_name"],
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });

 
};

export const getSubDistrict = async (district) => {
  // make API call here
  let subDistrictsData = [];
  await get(`/locations/subdistricts?district_code=${district}`).then(async (data) => {
    subDistrictsData = data.data
  });
  const newData = subDistrictsData.map((item, i) => ({
    id: item["sub_district_code"],
    value: item["sub_district_name"],
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getVillages = async (subdistrict) => {
  // make API call here
  let villegesData = [];
  await get(`/locations/villages?sub_district_code=${subdistrict.length===9? ('0'+ subdistrict):subdistrict}`).then(async (data) => {
    villegesData = data.data
  });
  const newData = villegesData.map((item, i) => ({
    id: item["village_code"],
    value: item["village_name"],
    ...item
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getUrbanBodies = async (subdistrict) => {
  // make API call here
  let UrbanBodiesData = [];
  await get(`/locations/urban-bodies?sub_district_code=${subdistrict.length===9? ('0'+ subdistrict):subdistrict}`).then(async (data) => {
    UrbanBodiesData = data.data
  });
  const newData = UrbanBodiesData.map((item, i) => ({
    id: item["urban_body_code"],
    value: item["urban_body_name"],
    ...item
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getTerritoryAndFOT = async (data) => {
  // make API call here
  console.log(data,'==')
  return {
    territory: data.territory_name,
    fot: data.fot_name,
    ...data
  };
};

export const getTerritoryAndFOTForUrbanBody = async (territory_id) => {
  // make API call here
  let FOTdetail = {};
  await get(`/locations/fot-mappings?territory_code=${territory_id}`).then(async (data) => {
    FOTdetail = data.data;
  });
  const newData = await FOTdetail.fots.map((item, i) => ({
    id: item["fot_code"],
    value: item["fot_name"],
  }));
  return { 'territory_name':FOTdetail.territory_name, fots:newData};
 
};

function groupResponsesByQuestionId(data) {
  const groupedResponses = {};

  data.responses.forEach((response) => {
    const { question_id, response: responseObj } = response;

    if (!groupedResponses[question_id]) {
      groupedResponses[question_id] = {
        question_id,
        response: {},
      };
    }

    Object.assign(groupedResponses[question_id].response, responseObj);
  });

  return Object.values(groupedResponses);
}

export const restructureAnswers = (config, answers) => {
  const outputObject = {};
  answers.forEach((item) => {
    outputObject[item.identifier] = item.answer;
  });

  const restructuredData = {
    responses: [],
  };

  const configMap = {};
  config.forEach((section, sectionIndex) => {
    if (section.sectionquestions) {
      section.sectionquestions.forEach((subSection) => {
        subSection.questions.forEach((question) => {
          configMap[question.identifier] = {
            sectionIndex,
            ...question,
          };
        });
      });
    } else {
      section.questions.forEach((question) => {
        configMap[question.identifier] = {
          sectionIndex,
          ...question,
        };
      });
    }
  });

  const groupedResponses = {};
  answers.forEach((answer) => {
    const questionConfig = configMap[answer.identifier];
    const { sectionIndex } = questionConfig;

    if (!groupedResponses[sectionIndex]) {
      groupedResponses[sectionIndex] = [];
    }

    groupedResponses[sectionIndex].push({
      question_id: sectionIndex + 1,
      response: {
        [questionConfig.identifier]: answer.answer,
      },
    });
  });

  // Flatten the grouped responses into a single array
  const flattenedResponses = [];
  for (const sectionIndex in groupedResponses) {
    flattenedResponses.push(...groupedResponses[sectionIndex]);
  }

  restructuredData.responses = flattenedResponses;
  const groupedData = groupResponsesByQuestionId(restructuredData);
  return groupedData;
};
