import { useEffect, useRef, useState } from "react";
import { Header } from "../components/headerTypeOne";
import { config1 } from "../config/step1";
import { Constants } from "../constants/textconstants";
import { Style } from "../styles/step1";
import { SaveAndCont } from "../components/saveandcont";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Cordinates,
  PhotoController,
  RadioControl,
  SingleSelect,
  TextDate,
} from "../helper-functions/utils";
import { setLoader, showToast } from "../store/slices/util";
import { useDispatch, useSelector } from "react-redux";
import { ProceedNotification } from "../components/proceedNotify";
import {
  getDistrict,
  getSubDistrict,
  getTerritoryAndFOT,
  getTerritoryAndFOTForUrbanBody,
  getUrbanBodies,
  getVillages,
  restructureAnswers,
} from "../helper-functions";
import { get, post } from "../config/api";
import { HasLiscence } from "../components/hasLiscence";
import { SaveProgress } from "../components/saveProgress";
import { LoaderFaded } from "../components/loaderfaded";
import { InfoPopUp } from "../components/infoPopuop";

export const Step1 = () => {
  const [formData, setFormData] = useState({});
  const data = useLocation();
  const [fieldErrors, setFieldErrors] = useState({});
  const [enabledFields, setEnabledFields] = useState({
    district: !data.state.isNewUser,
    subdistrict: !data.state.isNewUser,
    radioSelection: !data.state.isNewUser,
    village: false,
    urban: false,
    territory: !data.state.isNewUser,
    fot: !data.state.isNewUser,
  });

  const [locationError, setLocationError] = useState(null);
  const navigate = useNavigate();

  const webcamRef = useRef(null);
  const [image, setImage] = useState(null);
  const [clickToCapture, setClickToCapture] = useState(false);
  const dispatch = useDispatch();
  const [reviewInfo, setReviewInfo] = useState(false);
  const [config, setConfig] = useState(config1);
  const [showSorryPup, setshowSorryPup] = useState(false);
  const [saveProgress, setSaveProgress] = useState(false);
  const [localUrban, setUrbans] = useState(null);
  const Utils = useSelector((state) => state.Utils);
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showToast({ toast: true, toastmessage: "OTP verified successfully!" }));
      setTimeout(() => {
        dispatch(showToast({ toast: false, toastmessage: "" }));
      }, 1500);
  
      if (!data.state.createNewUser) {
        try {
          dispatch(setLoader(true));
          const response = await get(`/survey/responses?customer_id=${data.state.retailer.customer_id}&page_number=page1&user_id=${data.state.userID}`);
          
          let tempFormData = {};
          config.map((fields)=>{
            response.data[0].responses.map((responsefield)=>{
              if(fields.qID == responsefield.question_id){
                tempFormData[fields.identifier] = responsefield.response;
              }
            })
          });
         
          setFormData(tempFormData)
          dispatch(setLoader(false));
          
        } catch (error) {
          console.log(error);
        }
      }
  
      setFormData((prev) => ({
        ...prev,
        ["mobile_number"]: data.state.mobile_number,
      }));
    };
  
    fetchData();
  }, [data.state]);

  const fetchPresignedUrl = async (name) => {
    try {
      let filename = name;
      await get(
        `/presigned-url?file_name=${filename}&content_type=image/jpeg`
      ).then(async (response)=>{
        handleChange("shopPhoto", response.cdn_url);
        console.log('1', image)
        const blob =  base64ToBlob(image, "image/jpeg");
        console.log('2')
        const file = new File([blob], name, { type: "image/jpeg" });
        console.log('3')
        console.log("____________")
        await fetch(response.upload_url, {
          method: "PUT",
          headers: {
            "Content-Type": "image/jpeg",
          },
          body: file,
        });
      });
      


     
    } catch (error) {
      console.error("Error fetching presigned URL:", error);
    }
  };

  useEffect(() => {
    if (data.state.retailer) {
      setFormData((prev) => ({
        ...prev,
        ["shop_name"]: data.state.retailer.shop_name,
        ["mobile_number"]: data.state.retailer.mobile_number,
      }));
    }
  }, [data.state]);

  const handleChange = async (identifier, value) => {
    console.log(value, "------------------------");
    if (typeof value === "object") {
      if (identifier == "state") {
        setFormData((prev) => ({ ...prev, ["state_id"]: value.id }));
        dispatch(setLoader(true));
        getDistrict(value.id).then((districts) => {
          const updatedConfig = config.map((item) => {
            if (item.identifier === "district") {
              return { ...item, options: districts };
            }
            return item;
          });
          dispatch(setLoader(false));
          setConfig(updatedConfig);
        });
      } else if (identifier === "district") {
        setFormData((prev) => ({ ...prev, ["district_id"]: value.id }));
        dispatch(setLoader(true));
        getSubDistrict(value.id).then((subdistricts) => {
          const updatedConfig = config.map((item) => {
            if (item.identifier === "subdistrict") {
              return { ...item, options: subdistricts };
            }
            return item;
          });
          dispatch(setLoader(false));
          setConfig(updatedConfig);
        });
      } else if (identifier === "subdistrict") {
        setFormData((prev) => ({ ...prev, ["sub_district_id"]: value.id }));
        dispatch(setLoader(true));
        await getUrbanBodies(value.id).then((urbans) => {
          setUrbans(urbans);
          const updatedConfig = config.map((item) => {
            if (item.identifier === "urban") {
              return { ...item, options: urbans };
            }
            return item;
          });

          setConfig(updatedConfig);
        });
        await getVillages(value.id).then((villages) => {
          const updatedConfig = config.map((item) => {
            if (item.identifier === "village") {
              return { ...item, options: villages };
            }
            return item;
          });
          setConfig(updatedConfig);
        });
        dispatch(setLoader(false));
      } else if (identifier === "village") {
        setFormData((prev) => ({ ...prev, ["village_id"]: value.id }));
        dispatch(setLoader(true));
        await getTerritoryAndFOT(value).then((data) => {
          const updatedConfig1 = config.map((item) => {
            if (item.identifier === "territory") {
              setFormData((prev) => ({
                ...prev,
                ["territory"]: data["territory"],
              }));
              return { ...item, autopopulateColumn: true };
            }
            if (item.identifier === "fot") {
              setFormData((prev) => ({ ...prev, ["fot"]: data["fot"] }));
              return { ...item, autopopulateColumn: true };
            }
            return item;
          });
          dispatch(setLoader(false));
          setConfig(updatedConfig1);
        });
      } else if (identifier === "urban") {
        console.log(data.state, "******");
        let territory = data.state.territoryID;
        dispatch(setLoader(true));
        await getTerritoryAndFOTForUrbanBody(territory).then(async (data) => {
          const updatedConfig = await config.map((item) => {
            if (item.identifier === "territory") {
              setFormData((prev) => ({
                ...prev,
                ["territory"]: data["territory_name"],
              }));
              return { ...item, autopopulateColumn: true };
            }
            if (item.identifier === "fot") {
              return {
                ...item,
                options: data.fots,
                autopopulateColumn: false,
              };
            }
            return item;
          });
          dispatch(setLoader(false));
          setConfig(updatedConfig);
        });
      }

      if (identifier == "cordinates") {
        setFormData((prev) => ({ ...prev, [identifier]: value }));
      } else {
        setFormData((prev) => ({ ...prev, [identifier]: value.value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [identifier]: value }));
    }

    if (data.state.isNewUser) {
      // Enable dependent fields
      if (identifier === "state") {
        setEnabledFields((prev) => ({
          ...prev,
          district: true,
          subdistrict: false,
          radioSelection: false,
          village: false,
          urban: false,
          territory: false,
          fot: false,
        }));
        setFormData((prev) => ({
          ...prev,
          ["district"]: null,
          ["subdistrict"]:null,
          ["village"]: null,
          ["urban"]:null,
          ['territory']: null,
          ["fot"]: null
        }));
      } else if (identifier === "district") {
        setEnabledFields((prev) => ({
          ...prev,
          subdistrict: true,
          radioSelection: false,
          village: false,
          urban: false,
          territory: false,
          fot: false,
        }));
        setFormData((prev) => ({
          ...prev,
          ["subdistrict"]:null,
          ["village"]: null,
          ["urban"]:null,
          ['territory']: null,
          ["fot"]: null
        }));
      } else if (identifier === "subdistrict") {
        setEnabledFields((prev) => ({
          ...prev,
          radioSelection: true,
          village: false,
          urban: false,
          territory: false,
          fot: false,
        }));
        setFormData((prev) => ({
          ...prev,
          
          ["village"]: null,
          ["urban"]:null,
          ['territory']: null,
          ["fot"]: null
        }));
        handleChange("radioSelection", "Village");
      } else if (identifier === "businesspartner") {
        setFormData((prev) => ({
          ...prev,
          ["customerType"]: null,
        }));
      } else if (identifier === "radioSelection") {
        setEnabledFields((prev) => ({
          ...prev,
          village: value === "Village",
          urban: value === "Urban Body",
          territory: false,
          fot: false,
        }));
        if (value === "Urban Body") {
          const updatedConfig = config.map((item) => {
            if (item.identifier === "urban") {
              return { ...item, options: localUrban };
            }
            return item;
          });
          setConfig(updatedConfig);
        }
      } else if (identifier === "village" || identifier === "urban") {
        setEnabledFields((prev) => ({
          ...prev,
          territory: true,
          fot: true,
        }));
      }
    }
  };

  // Validate required fields
  const validateFields = () => {
    const errors = {};
    config.forEach((field) => {
      const { identifier, isOptional } = field;

      if (!isOptional && !formData[identifier]) {
        errors[identifier] = true;
      }
      if (
        identifier === "liscencePesticide" ||
        identifier === "liscenceSeeds" ||
        identifier === "liscenceFertilizer"
      ) {
        if (formData[identifier] === "Village") {
          errors[identifier] = true;
        }
      }
    });

    setFieldErrors(errors);
    if(Object.keys(errors).length !== 0){
      setShowError(true);
    }
    return Object.keys(errors).length === 0;
  };
  const close = () => {
    setReviewInfo(false);
  };
  const createPostBody = (selectedAnswers) => {
    let updatedPostBody = [];
    config.forEach((item) => {
      updatedPostBody.push({
        question_id: item.qID,
        response: formData[item.identifier],
      });
    });
    return updatedPostBody;
  };
  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const saveProgresses = async () => {
    dispatch(setLoader(true));
    await getLocation("cordinates");
    let PostBody = createPostBody(formData);

    const structured = {
      user_id: data.state.userID
        ? data.state.userID
        : data.state.parsedParameters
        ? data.state.parsedParameters.user_id
        : "",
      page_number: "page1",
      responses: PostBody,
      mobile_number: formData["mobile_number"],
      action: "save",
    };
    const createUserdata = {
      customer_type: "Retailer",
      village_id: formData["village_id"],
      sub_district: formData["subdistrict"],
      fot_1: formData["fot"],
      shop_name: formData["name"],
      fot_1_id: "IN50524",
      district: formData["district"],
      sub_district_id: formData["sub_district_id"],
      district_id: formData["district_id"],
      state: formData["state"],
      state_id: formData["state_id"],
      mobile_number: formData["mobile_number"],
      village: formData["village"],
      territory_id: data.state.territoryID,
      territory: formData["territory"],
    };

    if (data.state.createNewUser) {
      post("/retailers", createUserdata)
        .then(async (response) => {
          if (response.data.retailer_id) {
            console.log("Reached");
            structured["customer_id"] = response.data.retailer_id;
            await post("/survey/save", structured)
              .then((response) => {
                console.log(response);
                dispatch(setLoader(false));
                // navigate(
                //   `/?territory_id=${data.state.territoryID}&fo_id=${
                //     data.state.userID
                //       ? data.state.userID
                //       : data.state.parsedParameters
                //       ? data.state.parsedParameters.user_id
                //       : ""
                //   }`
                // );
              })
              .catch((error) => {
                dispatch(setLoader(false));
                window.alert("Something went wrong!");
              });
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          window.alert("Something went wrong!");
        });
    } else {
      if (data.state.retailer.customer_id) {
        structured["customer_id"] = data.state.retailer.customer_id;
      }
      post("/survey/save", structured)
        .then((response) => {
          dispatch(setLoader(false));
          navigate(
            `/?territory_id=${data.state.territoryID}&fo_id=${data.state.parsedParameters.user_id}`
          );
        })
        .catch((error) => {
          dispatch(setLoader(false));
          window.alert("Something went wrong!");
        });
    }
  };

  const proceed = async () => {
    setReviewInfo(false);
    dispatch(setLoader(true));

    let PostBody = createPostBody(formData);

    const structured = {
      user_id: data.state.userID
        ? data.state.userID
        : data.state.parsedParameters
        ? data.state.parsedParameters.user_id
        : "",
      page_number: "page1",
      responses: PostBody,
      action: "save_next",
    };


    const createUserdata = {
      customer_type: "Retailer",
      village_id: formData["village_id"],
      sub_district: formData["subdistrict"],
      fot_1: formData["fot"],
      shop_name: formData["name"],
      fot_1_id: "IN50524",
      district: formData["district"],
      sub_district_id: formData["sub_district_id"],
      district_id: formData["district_id"],
      state: formData["state"],
      state_id: formData["state_id"],
      mobile_number: formData["mobile_number"],
      village: formData["village"],
      territory_id: data.state.territoryID,
      territory: formData["territory"],
    };



    if (data.state.retailer && data.state.retailer.customer_id) {
      structured["customer_id"] = data.state.retailer.customer_id;
    }


    if (data.state.createNewUser) {
      post("/retailers", createUserdata)
        .then(async (response) => {
          if (response.data.retailer_id) {
            console.log("Reached");
            structured["customer_id"] = response.data.retailer_id;
            await post("/survey/save", structured)
              .then((response) => {
                console.log(response);
                dispatch(setLoader(false));
                navigate("/step2", {
                  state: {
                    parsedParameters: data.state.parsedParameters,
                    customerID: structured["customer_id"],
                    user_id: data.state.userID,
                    territory_id: data.state.territoryID,
                    bayerSeller: formData["businesspartner"]
                  },
                });
              })
              .catch((error) => {
                console.log(error)
                dispatch(setLoader(false));
                window.alert("Something went wrong!1");
              });
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          window.alert("Something went wrong!2");
        });
    } else{
      post("/survey/save", structured)
      .then((response) => {

        navigate("/step2", {
          state: {
            parsedParameters: data.state.parsedParameters,
            customerID: data.state.retailer.customer_id,
            user_id: data.state.userID,
            territory_id: data.state.territoryID,
            bayerSeller: formData["businesspartner"]
          },
        });
      })
      .catch((error) => {
        dispatch(setLoader(false));
        window.alert("Something went wrong!");
        console.error("Error fetching data:", error);
      });
    }
  };
  const moveToStep2 = () => {
    if (validateFields()) {
      if (
        formData["liscencePesticide"] === "no" &&
        formData["liscenceSeeds"] === "no" &&
        formData["liscenceFertilizer"] === "no"
      ) {
        setshowSorryPup(true);
      } else {
        setReviewInfo(true);
      }
    } 
  };

  const getLocation = async (identifier) => {
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          handleChange(identifier, { latitude, longitude });
        },
        (error) => {
          setLocationError(error.message);
        }
      );
    } else {
      setLocationError("Geolocation is not supported by this browser.");
    }
  };
  const fetchRetailerDetails = async () => {
    if (!data.state.createNewUser) {
      setFormData((prev) => ({
        ...prev,
        ["shop_name"]: data.state.retailer.shop_name,
        ["mobile_number"]: data.state.retailer.mobile_number,
      }));
    }
    console.log(data.state, "----------------==-----------");
  };

  useEffect(() => {
    dispatch(setLoader(false));
    getLocation("cordinates");
    fetchRetailerDetails();
  }, []);



  const capturePhoto = async () => {
    try {
      dispatch(setLoader(true));
      const imageSrc = webcamRef.current.getScreenshot();
      await setImage(imageSrc);
      const blob = await fetch(imageSrc).then(res => res.blob());
      const file = new File([blob], 'captured_image.jpeg', { type: 'image/jpeg' });
  
      const response = await get(`/presigned-url?file_name=captured_image.jpeg&content_type=image/jpeg`);
      await fetch(response.upload_url, {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg",
        },
        body: file,
      });
  
      dispatch(setLoader(false));
    } catch (error) {
      console.error("Error capturing or uploading image:", error);
      // Handle error, e.g., display an error message to the user
    }
  };


  // const capturePhoto = async () => {
   
  //   const capturedImage =await webcamRef.current.getScreenshot();
  //   await setImage(capturedImage);
  //   dispatch(setLoader(false));
  // };

  useEffect(()=>{
    dispatch(setLoader(true));
    let filename = `${new Date().getTime()}.jpeg`;
    fetchPresignedUrl(filename);
    dispatch(setLoader(false));
  },[image])

  const renderField = (field) => {
    const {
      type,
      fieldName,
      identifier,
      placeholder,
      options,
      isHalf,
      isFirst,
      autopopulateColumn,
    } = field;

    const isDisabled =
      (identifier === "district" && !enabledFields.district) ||
      (identifier === "subdistrict" && !enabledFields.subdistrict) ||
      (identifier === "village" && !enabledFields.village) ||
      (identifier === "urban" && !enabledFields.urban) ||
      (identifier === "territory" && !enabledFields.territory) ||
      (identifier === "fot" && !enabledFields.fot);

    const hasError = fieldErrors[identifier];

    switch (type) {
      case "text":
      case "date":
        return (
          <TextDate
            identifier={identifier}
            fieldName={fieldName}
            type={type}
            placeholder={placeholder}
            formData={formData}
            handleChange={handleChange}
            hasError={hasError}
            
          />
        );
      case "singleSelect":
        return (
          <SingleSelect
            identifier={identifier}
            isHalf={isHalf}
            isFirst={isFirst}
            fieldName={fieldName}
            formData={formData}
            handleChange={handleChange}
            placeholder={placeholder}
            options={options}
            disabled={isDisabled}
            hasError={hasError}
            autopopulateColumn={autopopulateColumn}
          />
        );
      case "radio":
        return identifier === "radioSelection" ? (
          <RadioControl
            identifier={identifier}
            fieldName={fieldName}
            options={options}
            formData={formData}
            handleChange={handleChange}
            disabled={
              identifier === "radioSelection" && !enabledFields.radioSelection
            }
          />
        ) : (
          <RadioControl
            identifier={identifier}
            fieldName={fieldName}
            options={options}
            formData={formData}
            handleChange={handleChange}
            hasError={hasError}
          />
        );

      case "file":
        return (
          <PhotoController
            identifier={identifier}
            fieldName={fieldName}
            image={image}
            clickToCapture={clickToCapture}
            webcamRef={webcamRef}
            formData={formData}
            capturePhoto={capturePhoto}
            setClickToCapture={setClickToCapture}
            setImage={setImage}
          />
        );

      case "cordinates":
        return (
          <Cordinates
            identifier={identifier}
            fieldName={fieldName}
            getLocation={getLocation}
            formData={formData}
            locationError={locationError}
          />
        );

      default:
        return null;
    }
  };
  const showSaveProgress = () => {
    setSaveProgress(true);
  };

  return (
    <div>
    {showError && <InfoPopUp close={()=>{setShowError(false)}} heading={"All fields Mandatory*"} text={"Please fill all those fields including Photo to proceed further."}/>}
      
      {Utils.loading && <LoaderFaded />}
      {saveProgress && (
        <SaveProgress
          close={() => {
            setSaveProgress(false);
          }}
          saveprogress={() => {
            proceed();
            navigate(
              `/?territory_id=${data.state.territoryID}&fo_id=${data.state.UserID}`
            );
          }}
        />
      )}
      {showSorryPup && (
        <HasLiscence
          SaveAndExit={() => {
            setshowSorryPup(false);

            let PostBody = createPostBody(formData);
            const structured = {
              user_id: data.state.userID
                ? data.state.userID
                : data.state.parsedParameters
                ? data.state.parsedParameters.user_id
                : "",
              page_number: "page1",
              responses: PostBody,
              action: "discontinue",
            };
            post("/survey/save", structured)
              .then((response) => {
                
                dispatch(setLoader(false));
                navigate(
                  `/?territory_id=${data.state.territoryID}&fo_id=${data.state.parsedParameters.user_id}`
                );
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });

            navigate(
              `/?territory_id=${data.state.territoryID}&fo_id=${data.state.UserID}`
            );
          }}
          HaveLiscence={() => {
            setshowSorryPup(false);
          }}
        />
      )}
      {reviewInfo && <ProceedNotification close={close} proceed={proceed} />}
      <Header
        header={Constants.retailer_survey}
        subheader={Constants.step1}
        parsedParameters={data.state.parsedParameters}
        showSaveProgress={showSaveProgress}
      />
      <div style={Style.container}>
        {config.map((field) => renderField(field))}
      </div>
      <SaveAndCont onContinue={moveToStep2} saveProgress={saveProgresses} />
    </div>
  );
};
