import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Simulating an API call
export const Utilitycall = createAsyncThunk("util", async (_, thunkAPI) => {});

const UtilitySLice = createSlice({
  name: "util",
  initialState: {
    loading: false,
    toast: false,
    toastmessage: "",
  },
  reducers: {
    setLoaderTrue: (state, action) => {
      state.loading = true;
    },
    showToast: (state, action) => {
      state.toast = action.payload.toast;
      state.toastmessage = action.payload.toastmessage;
    },
    setLoader: (state, action)=>{
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(Utilitycall.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Utilitycall.fulfilled, (state, action) => {
 
      })
      .addCase(Utilitycall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setLoaderTrue, showToast, setLoader } = UtilitySLice.actions;
export default UtilitySLice.reducer;
