import { NoData } from "../constants";

export const NoStores = () => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:'column',
        zIndex:9
      }}
    >
      <img src={NoData} style={{ width: 100, height: 100 }} alt="loader" />
      <span style={{display:'block',width:'80%', textAlign:'center', marginTop:20}}>
        There are no retailers available in your territory.
      </span>
    </div>
  );
};
