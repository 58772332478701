import { colors, Font } from "../styles/standards"

export const Loader = () => {
    return(
        <div style={Styles.container}>
            <span style={Styles.loadertext}>Loading...</span>
        </div>
    )
}

export const Styles = {
    container:{
        position:'fixed',
        left:0,
        right:0,
        top:0,
        bottom:0,
        background:colors.White,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        zIndex:9999
    },
    loadertext:{
        ...Font.RobotoBoldItalic
    }
}