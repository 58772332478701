import { colors, Font, Gap } from "../styles/standards"

export const SaveProgress = ({close, saveprogress}) => {
    return(
        <div style={Styles.container}>
            <div style={Styles.popBody}>
                <span style={Styles.header}>Go back to HomePage?</span>
                <span style={Styles.sorryText}>Save your current progress before proceeding, You may resume survey later</span>
                <div  style={{...Styles.button, background:colors.Blue_Heilight, color:colors.White}} onClick={()=>{saveprogress()}}>Save Progress</div>
                <div style={{...Styles.button, color: colors.Blue_Heilight}} onClick={()=>{close()}}>Cancel</div>
                
            </div>
        </div>
    )
}
const Styles = {
    container:{
        position:'fixed',
        left:0,
        right:0,
        top:0,
        bottom:0,
        background:'rgba(0,0,0,0.7)',
        zIndex:999,
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    popBody:{
        width:'80%',
        background:colors.White,
        padding:Gap.standardGap
    },
    sorryText:{
        ...Font.RobotoMedium,
        fontSize:14
    },
    header:{
        display:'block',
        ...Font.RobotoBold,
        marginBottom:20
    },
    button:{
        width:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        paddingTop:15,
        paddingBottom:15,
        border: `1px solid ${colors.Blue_Heilight}`,
        ...Font.RobotoBold,
        fontSize: 14,
        marginTop:20
    }
}